<template>
  <div v-if="showGeneralContainer">
    <!-- PRECOMPRA NORMAL GLOBAL -->
    <div
      class="flex-container"
      v-if="
        !validateMareaSpecial() &&
        !reservationInfo.isEvent &&
        reservationInfo.typeReservation !== 'Pombo' &&
        reservationInfo.typeReservation !== 'Rumba' &&
        reservationInfo.typeReservation !== 'Cena' &&
        reservationInfo.typeReservation !== 'Brunch' &&
        reservationInfo.people >= params.minimumChargedPeople &&
        !getPayReservation() && !getPayReservationFixed()
      "
    >
      <span class="item-left-total">
        <span class="checkout-item-subtitle">{{ $t('prePurchase') }}</span>
        <p class="item-small-total">
          {{(params.purchaseCost || 0) | currency("$ ", 0, { thousandsSeparator: "," })}}*
           x{{ reservationInfo.people }} pax
        </p>
      </span>
      <p class="item-right-total">
        {{(params.purchaseCost * reservationInfo.people) | currency("$ ", 0, { thousandsSeparator: "," })}}
      </p>
    </div>
    <!-- FIN PRECOMPRA NORMAL GLOBAL -->

    <!-- ADULTOS Y NIÑOS -->
    <div
      class="flex-container"
      v-if="
        !validateMareaSpecial() &&
        !reservationInfo.isEvent &&
        reservationInfo.typeReservation !== 'Pombo' &&
        reservationInfo.typeReservation !== 'Rumba' &&
        reservationInfo.typeReservation !== 'Cena' &&
        reservationInfo.typeReservation !== 'Brunch' &&
        getPayReservation() &&
        getPayReservationBoy() &&
        reservationInfo.boy > 0
      "
    >
      <span class="item-left-total">
        <span class="checkout-item-subtitle">{{ $t('prePurchase') }}</span>
        <p class="item-small-total">
          {{getPayReservationValue() | currency("$ ", 0, { thousandsSeparator: "," })}}*
          x{{ reservationInfo.adult }} pax
        </p>
      </span>
      <p class="item-right-total">
        {{(getPayReservationValue() * reservationInfo.adult) | currency("$ ", 0, { thousandsSeparator: "," })}}
      </p>
    </div>
    <div
      class="flex-container"
      v-if="
        !validateMareaSpecial() &&
        !reservationInfo.isEvent &&
        reservationInfo.typeReservation !== 'Pombo' &&
        reservationInfo.typeReservation !== 'Rumba' &&
        reservationInfo.typeReservation !== 'Cena' &&
        reservationInfo.typeReservation !== 'Brunch' &&
        getPayReservation() &&
        getPayReservationBoy() &&
        reservationInfo.boy > 0
      "
    >
      <span class="item-left-total">
        <span class="checkout-item-subtitle">{{ $t('prePurchase') }}</span>
        <p class="item-small-total">
          {{getPayReservationBoyValue() | currency("$ ", 0, { thousandsSeparator: "," })}}*
          x{{ reservationInfo.boy }} pax
        </p>
      </span>
      <p class="item-right-total">
        {{(getPayReservationBoyValue() * reservationInfo.boy) | currency("$ ", 0, { thousandsSeparator: "," })}}
      </p>
    </div>
    <!-- FIN ADULTOS Y NIÑOS -->

    <!-- PRECOMPRA PRECIO FIJO O NORMAL SIN REVISAR PAGO POR PERSONA GLOBAL -->
    <div
      class="flex-container"
      v-if="
        !validateMareaSpecial() &&
        !reservationInfo.isEvent &&
        reservationInfo.typeReservation !== 'Pombo' &&
        reservationInfo.typeReservation !== 'Rumba' &&
        reservationInfo.typeReservation !== 'Cena' &&
        reservationInfo.typeReservation !== 'Brunch' &&
        (getPayReservation() || getPayReservationFixed())
      "
    >
      <span class="item-left-total" v-if="getPayReservation()">
        <span class="checkout-item-subtitle">{{ $t('prePurchase') }}</span>
        <p class="item-small-total">
          {{getPayReservationValue() | currency("$ ", 0, { thousandsSeparator: "," })}}*
          x{{ reservationInfo.people }} pax
        </p>
      </span>
      <p class="item-right-total" v-if="getPayReservation()">
        {{(getPayReservationValue() * reservationInfo.people) | currency("$ ", 0, { thousandsSeparator: "," })}}
      </p>
      <span class="item-left-total" v-if="getPayReservationFixed()">
        <span class="checkout-item-subtitle">{{ $t('prePurchase') }}</span>
        <p class="item-small-total">
          {{getPayReservationFixedValue() | currency("$ ", 0, { thousandsSeparator: "," })}}
        </p>
      </span>
      <p class="item-right-total" v-if="getPayReservationFixed()">
        {{(getPayReservationFixedValue() * 1) | currency("$ ", 0, { thousandsSeparator: "," })}}
      </p>
    </div>
    <!-- FIN PRECOMPRA PRECIO FIJO O NORMAL SIN REVISAR PAGO POR PERSONA GLOBAL -->
  </div>
</template>
<script>
export default {
  props: ['reservationInfo'],
  computed: {
    showGeneralContainer () {
      let reservationInfo = this.reservationInfo;
      if ((!this.validateMareaSpecial() &&
        !reservationInfo.isEvent &&
        reservationInfo.typeReservation !== 'Pombo' &&
        reservationInfo.typeReservation !== 'Rumba' &&
        reservationInfo.typeReservation !== 'Cena' &&
        reservationInfo.typeReservation !== 'Brunch' &&
        reservationInfo.people >= this.params.minimumChargedPeople &&
        !this.getPayReservation() && !this.getPayReservationFixed()) ||
        (!this.validateMareaSpecial() &&
        !reservationInfo.isEvent &&
        reservationInfo.typeReservation !== 'Pombo' &&
        reservationInfo.typeReservation !== 'Rumba' &&
        reservationInfo.typeReservation !== 'Cena' &&
        reservationInfo.typeReservation !== 'Brunch' &&
        this.getPayReservation() &&
        this.getPayReservationBoy() &&
        reservationInfo.boy > 0) ||
        (!this.validateMareaSpecial() &&
        !reservationInfo.isEvent &&
        reservationInfo.typeReservation !== 'Pombo' &&
        reservationInfo.typeReservation !== 'Rumba' &&
        reservationInfo.typeReservation !== 'Cena' &&
        reservationInfo.typeReservation !== 'Brunch' &&
        (this.getPayReservation() ||
        this.getPayReservationFixed)) ||
        (!this.validateMareaSpecial() &&
        !reservationInfo.isEvent &&
        reservationInfo.typeReservation !== 'Pombo' &&
        reservationInfo.typeReservation !== 'Rumba' &&
        reservationInfo.typeReservation !== 'Cena' &&
        reservationInfo.typeReservation !== 'Brunch' &&
        this.getPayReservation() &&
        (!this.getPayReservationBoy() ||
          (this.getPayReservationBoy() && reservationInfo.boy === 0)))) return true;
      return false;
    }
  },
  methods: {
    validateMareaSpecial () {
      if (
        this.vendor.id === 231 &&
        (this.costAdultMarea > 0 || this.costBoyMarea > 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    getPayReservation () {
      let data = false;
      let info = window.localStorage.getItem('payReservations');
      if (info) {
        data = true;
      }
      return data;
    },
    getPayReservationValue () {
      let data = 0;
      let info = window.localStorage.getItem('payReservations');
      if (info) {
        data = parseInt(info);
      }
      return data;
    },
    getPayReservationFixed () {
      let data = false;
      let info = window.localStorage.getItem('payReservationsFixed');
      if (info) {
        data = true;
      }
      return data;
    },
    getPayReservationFixedValue () {
      let data = 0;
      let info = window.localStorage.getItem('payReservationsFixed');
      if (info) {
        data = parseInt(info);
      }
      return data;
    },
    getPayReservationBoy () {
      let data = false;
      let info = window.localStorage.getItem('payReservationBoys');
      if (info) {
        data = true;
      }
      return data;
    },
    getPayReservationBoyValue () {
      let data = 0;
      let info = window.localStorage.getItem('payReservationBoys');
      if (info) {
        data = parseInt(info);
      }
      return data;
    }
  }
};
</script>
