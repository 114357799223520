<template>
  <div class="sure-options-reload">
    <i class="icon-mks icon_time_over"></i>
    <h2 class="sure-options-reload__title">
      {{$t('timeOutReservation')}}
    </h2>
    <div class="sure-options-reload__timer" v-if="timeLapCronometer">
      <iconTime :color="colorCronometer"></iconTime><span :style="{color: colorCronometer, fontWeight: 600}">{{ timeLapCronometer }}</span>
    </div>
    <div class="sure-options-reload__actions">
      <b-button @click="addMoreTime">{{$t('extendTime')}}</b-button>
      <b-button @click="reloadWithoutStatus">{{$t('getOutTime')}}</b-button>
    </div>
  </div>
</template>

<script>
import iconTime from '@/components/Icons/iconTime';
export default {
  props: ['timeLaps'],
  components: {
    iconTime
  },
  data () {
    return {
      timeLapCronometer: null,
      colorCronometer: '#f44336'
    };
  },
  mounted () {
    this.counterLaps();
    window.localStorage.setItem('alert_timing', 1);
  },
  methods: {
    counterLaps () {
      let laps = this.timeLaps - 1;
      const thes = this;
      let interval = setInterval(() => {
        if (laps === 0) {
          clearInterval(interval);
        }
        thes.timeLapCronometer = (laps < 10) ? '00:0' + laps : '00:' + laps;
        laps--;
      }, 1000);
    },
    addMoreTime () {
      this.$emit('addMoreTime');
      this.$parent.close();
    },
    reloadWithoutStatus () {
      this.$parent.close();
    }
  }
};
</script>

<style lang="scss">
  .modal-sure-reload-reservation{
    .animation-content{
      background-color: white;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 2px 2px 9px 1px rgba(0, 0, 0, 0.2);
      .sure-options-reload{
        display: grid;
        gap: 10px;
        text-align: center;
        .icon_time_over{
          width: 60px;
          height: 60px;
          margin: 0 auto;
          background-color: rgb(255 151 63);
        }
        &__title{
          font-weight: 600;
          font-size: 16px;
        }
        &__timer {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 22px;
          gap: 5px
        }
        p{
          font-size: 14px;
        }
        &__actions{
          display: flex;
          gap: 10px;
          button{
            border-radius: 8px;
            width: 50%;
            font-weight: 600;
            font-size: 13px!important;
            height: 40px!important;
            &:hover{
              background: black;
              color: white;
            }
          }
        }
      }
    }
  }
</style>
