<template>
  <b-modal
    :active.sync="modal"
    :can-cancel="false"
    :style="{height:altura+'px'}"
    @scroll.stop
    class="modal-size-experience"
  >
    <div
      :class="{personActive:personActive,lessThanthree:listExperience.length<3, readyTosubmit:stateButton}"
      id="experiencesCheckout"
    >
      <div @click="close" class="experience__close" v-if="!edit">
        <img alt src="@/assets/images/icons/close.svg" srcset />
      </div>
      <section class="list_experiences">
        <div class="list_experiences__title">
          <h2 v-if="params.titleExperience">{{ params.titleExperience }}</h2>
          <h2 v-else>{{ $t('modalExperiences.text1') }}</h2>
        </div>
        <div
          :class="{moreThanthree:listExperience.length>3,noImagesType:noImagesType }"
          class="overflow_container columns flex-flow-w"
        >
          <div
            :class="{noImages:!experience.imageExperience , doubleLine:acviteDoubleTitle,selectorInNoimages:personActive && !experience.imageExperience ,selectorImages:personActive,hidePriceOnCard:!experience.price}"
            :key="'experience'+ index"
            @click="personActive && setExperienceOnperson(experience._id)"
            class="list_experiences__card"
            v-for="(experience, index) in listExperience"
          >
            <div class="selectExperience" v-show="personActive">
              <b-radio
                :native-value="experience._id"
                @input="setExperienceOnperson()"
                size="is-small"
                type="is-black"
                v-model="radio"
              ></b-radio>
            </div>
            <div class="list_experiences__card__container_img" v-if="experience.imageExperience">
              <div class="img_cont_aux">
                <img :src="experience.imageExperience" alt />
              </div>
            </div>
            <div class="list_experiences__card__container_info">
              <h3
                :class="{acviteDoubleTitle:acviteDoubleTitle}"
                ref="panpan"
              >{{textFormatter(experience.name, 40) }}</h3>
              <div class="description">
                <span
                  @click="openModalDetailExperience(experience)"
                  class="show_more_modal"
                >{{ $t('modalExperiences.text2') }}</span>
                <p
                  :class="{shortFourDouble:acviteDoubleTitle}"
                  class="experienceDescription"
                  v-html="experience.description"
                ></p>
              </div>
              <div :class="{hideText:!experience.price}" class="pt-1 price" v-if="experience.price">
                <span>$ {{ experience.price | currency("", 0, { thousandsSeparator: "." }) }}</span> {{ $t('modalExperiences.text3') }}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="options_people_experiences" v-show="!params.experiencePerReservation">
        <p class="titleOptions" v-if="params.descriptionExperience">{{params.descriptionExperience}}</p>
        <p class="titleOptions" v-else>
          {{ $t('modalExperiences.text4') }}
          <b>{{ $t('modalExperiences.text5') }}</b> {{ $t('modalExperiences.text6') }}
        </p>
        <section class="personsContainer">
          <div
            :class="{cardExperienceSelected:personActive===person,already:person.experience} "
            :key="'experiencePerson' + index"
            @click="personClicked(person)"
            class="personCard"
            v-for="(person, index) in listPersonExperience"
          >
            <div class="personBlock">
              <b-checkbox :value="!!person.name" class="checkbox" size="is-small" type="is-black"></b-checkbox>
              <p
                class="personSelectorExperience"
              >{{textFormatter('P'+(index + 1)+': ' + (!person.name ? 'Añadir experiencia' : person.name) , 22) }}</p>
            </div>
          </div>
        </section>
      </section>
      <div class="container_action" v-if="listPersonExperience[0]">
        <button
          :class="{'save': !stateButton , nextPerson:selectedIsReady}"
          @click="sendDataModal()"
          class="container_action__continue cursor_pointer"
        >{{selectedIsReady ? 'Siguiente experiencia': 'Continuar'}}</button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import 'vue-select/dist/vue-select.css';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import modalDetailExperience from '@/components/Checkout/sub_modals/modalDetailExperience';
import { mapGetters } from 'vuex';

export default {
  props: ['reservation', 'listExperience'],
  data () {
    return {
      modal: true,
      noImage: true,
      edit: false,
      selectedOption: '',
      listPersonExperience: [],
      count: 0,
      stateButton: false,
      personActive: false,
      radio: null,
      acviteDoubleTitle: false,
      altura: 0
    };
  },
  computed: {
    ...mapGetters({
      experienceCost: reservationTypes.getters.getExperienceCost,
      modalExp: whiteLabelTypes.getters.stateModalExperience
    }),
    noImagesType () {
      if (this.listExperience[0].imageExperience) {
        return false;
      }
      return true;
    },
    selectedIsReady () {
      if ((this.listPersonExperience.find((e) => e === this.personActive))) {
        return !!(this.listPersonExperience.find((e) => e === this.personActive).experience) && !this.stateButton;
      } else {
        return false;
      }
    }
  },
  mounted () {
    window.addEventListener('click', (e) => {
      if (e) {
        // eslint-disable-next-line no-empty
        if (e.target.className.includes('description') || e.target.className.includes('list_experiences__card') || e.target.className.includes('personCard') || e.target.className.includes('experienceDescription') || e.target.className.includes('save')) {
        } else {
          if (this.params.experiencePerReservation) {
            this.personActive = true;
          } else {
            this.personActive = false;
          }
        }
      }
    });
    const resizeObserver = new ResizeObserver(() => {
      if (window.innerWidth > 999) {
        document.getElementsByClassName('personsContainer')[0].style.width = document.getElementsByClassName('overflow_container')[0].offsetWidth + 'px';
      }
    });
    resizeObserver.observe(document.getElementsByClassName('overflow_container')[0]);
    if (this.reservation.experiences && this.reservation.experiences != null && this.reservation.experiences.length > 0) {
      this.edit = true;
      this.reservation.experiences.forEach((exp, index) => {
        this.listPersonExperience.push({
          person: (index + 1),
          experience: exp.experience == null ? '' : exp.experience,
          name: exp.name == null ? '' : exp.name
        });
        this.stateButton = true;
      });
    } else {
      this.edit = false;
      this.getListPlates();
    }
    if (this.listExperience.length > 0 && this.params.optionRequiredExperiencie === 1) {
      this.edit = true;
    }
    // eslint-disable-next-line no-constant-condition
    if (this.params.experiencePerReservation) {
      this.personActive = true;
      const firstPersonExperience = this.listPersonExperience[0].experience;
      if (firstPersonExperience) {
        this.radio = firstPersonExperience;
      }
    }
  },
  updated () {
    if (this.$refs.panpan) {
      if (this.$refs.panpan.findIndex((h3) => {
        return h3.clientHeight > 35;
      }) >= 0) {
        this.acviteDoubleTitle = true;
      }
    }
  },
  created () {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    this.altura = window.innerHeight;
    setTimeout(() => {
      const disabled = document.getElementsByClassName('vs__search');
      for (let i = 0; i < disabled.length; i++) {
        if (disabled.length > 0) {
          disabled[i].setAttribute('readonly', true);
        }
      }
    }, 100);
  },
  methods: {
    textFormatter (word, maxSize) {
      if (word.length > maxSize) {
        return word.slice(0, maxSize) + '...';
      }
      return word;
    },
    expandSelect (id) {
      this.expanded_option['_people_' + id] = true;
    },
    getListPlates () {
      for (let i = 1; i <= this.reservation.people; i++) {
        this.listPersonExperience.push({
          person: i,
          experience: ''
        });
      }
    },
    personClicked (person) {
      this.personActive = person;
      this.radio = person.experience;
    },
    setExperienceOnperson (id) {
      if (this.radio !== id) {
        this.radio = id;
      }
      // eslint-disable-next-line no-constant-condition
      if (this.params.experiencePerReservation) {
        const experienceSelected = this.listExperience.find(element => element._id === this.radio);
        this.listPersonExperience.forEach((element, index) => {
          this.listPersonExperience[index].experience = experienceSelected._id;
          this.listPersonExperience[index].name = experienceSelected.name;
          this.listPersonExperience[index].experienceIndex = this.radio;
        });
        this.stateButton = true;
      } else {
        this.personActive.name = this.listExperience.find(element => element._id === this.radio);
        this.validateSelects();
      }
    },
    validateSelects () {
      this.count = 0;
      this.listPersonExperience.forEach((element, index) => {
        if (typeof element.name === 'object') {
          this.listPersonExperience[index].experience = element.name._id;
          this.listPersonExperience[index].name = element.name.name;
          this.listPersonExperience[index].experienceIndex = this.radio;
        }
        if (element.experience !== undefined && element.experience !== null && element.experience !== '') {
          this.count += 1;
        }
      });
      if (this.count >= this.listPersonExperience.length) {
        this.stateButton = true;
      }
    },
    sendDataModal () {
      if (this.stateButton) {
        let costCount = 0;
        this.listPersonExperience.forEach((element, index) => {
          const { realPrice } = this.listExperience.find(x => x._id === element.experience);
          if (this.params.experiencePerReservation) {
            costCount = realPrice;
          } else {
            costCount += realPrice;
          }
        });
        if (!window.localStorage.getItem('_oldPayExperience')) {
          window.localStorage.setItem('_oldPayExperience', parseInt(this.reservation.balance));
          window.localStorage.setItem('_oldPayExperienceFull', parseInt(this.reservation.totalBalance));
        }
        this.$store.commit(reservationTypes.mutations.setExperienceCost, costCount);
        this.reservation.experiences = this.listPersonExperience;
        window.localStorage.setItem(
          '_reservation',
          btoa(JSON.stringify(this.reservation))
        );
        this.$store.commit(whiteLabelTypes.mutations.setStateRequiredException, true);
        if (this.reservation.isFree === false) {
          if (this.params.paymentTypeActive === 1 && this.params.paymentTypePSEActive === 1) {
            this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
          }
        }
        /* if (this.experienceCost > 0) {
          this.reservation.balance = parseInt(this.reservation.balance) + parseInt(this.experienceCost);
          if (this.reservation.residuo === undefined) {
            this.reservation.totalBalance = parseInt(this.reservation.balance);
          } else {
            this.reservation.totalBalance = parseInt(this.reservation.totalBalance) + parseInt(this.experienceCost);
          }
          window.localStorage.setItem('_reservation', btoa(JSON.stringify(this.reservation)));
        } else {
          this.reservation.balance = parseInt(window.localStorage.getItem('_oldPayExperience'));
          this.reservation.totalBalance = parseInt(window.localStorage.getItem('_oldPayExperienceFull'));
          if (this.reservation.balance <= 0) {
            this.reservation.isFree = true;
            this.minutes = 2;
          }
          window.localStorage.setItem('_reservation', btoa(JSON.stringify(this.reservation)));
        } */
        this.$store.commit(reservationTypes.mutations.setReservationsUser);
        this.$emit('pay');
        this.$store.commit(whiteLabelTypes.mutations.setStateRequiredException, true);
        this.$store.commit(whiteLabelTypes.mutations.setModalExperience, false);
      } else if (this.personActive) {
        for (let index = 0; index < this.listPersonExperience.length; index++) {
          if (!this.listPersonExperience[index].name) {
            this.personActive = this.listPersonExperience[index];
            this.radio = null;
            break;
          }
        }
      }
    },
    close () {
      this.$store.commit(whiteLabelTypes.mutations.setStateRequiredException, false);
      this.$store.commit(whiteLabelTypes.mutations.setModalExperience, false);
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    },
    openModalDetailExperience (experience) {
      let instance = this;
      this.$buefy.modal.open({
        parent: instance,
        component: modalDetailExperience,
        hasModalCard: true,
        props: {
          experience: experience
        },
        canCancel: true,
        scroll: 'keep',
        events: {
        }
      });
    }
  },
  destroyed () {
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/Experiences/_index.scss";
.modal-size-experience {
  width: 100vw;
  max-height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
