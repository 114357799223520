<template>
  <div v-if="validateMareaSpecial()">
    <div class="flex-container" v-if="validateMareaSpecial()">
      <span class="item-left-total">
        <span class="checkout-item-subtitle">{{ $t('prePurchase') }}</span>
        <p class="item-small-total">
          {{ payAdultMarea | currency("$ ", 0, { thousandsSeparator: "," }) }} *
          {{ reservationInfo.adult }} {{ $t("adults") }}
        </p>
      </span>
      <p class="item-right-total">
        {{ (payAdultMarea * reservationInfo.adult) | currency("$ ", 0, { thousandsSeparator: "," }) }}
      </p>
    </div>
    <div class="flex-container" v-if="validateMareaSpecial() && reservationInfo.boy > 0">
      <span class="item-left-total">
        <span class="checkout-item-subtitle">{{ $t('prePurchase') }}</span>
        <p class="item-small-total">
          {{ payBoyMarea | currency("$ ", 0, { thousandsSeparator: "," }) }} *
          {{ reservationInfo.boy }} {{ $t("kids") }}
        </p>
      </span>
      <p class="item-right-total">
        {{ (payBoyMarea * reservationInfo.boy) | currency("$ ", 0, { thousandsSeparator: "," })}}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ['reservationInfo', 'payBoyMarea', 'payAdultMarea'],
  methods: {
    validateMareaSpecial () {
      if (
        this.vendor.id === 231 &&
        (this.payAdultMarea > 0 || this.payBoyMarea > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
