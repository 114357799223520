<template>
  <div class="metohpayment">
    <div class="columns-container" :class="{'mp-background': params.paymentType == 'MP'}">
      <div class="column btn-payment" v-if="params.paymentTypeActive === 1" :class="{inactive: activeCredit == false, rounded: activeCredit == null, bgByDefaultButtons : !paymentType}" @click="selectPayment(1); activeCredit = true;">
        <p class="column-title" :class="{'black-tittle': validateTitle}"> {{ $t('creditCard') }} </p>
        <span class="card-images">
          <img src="img/visa-checkout.png" class="img-card visa-img">
          <img src="img/master-checkout.png" class="img-card master-img">
          <img src="img/diners-checkout.png" class="img-card diners-img">
          <img src="img/american-checkout.png" class="img-card american-img">
        </span>
      </div>
      <div class="column btn-payment" v-if="params.paymentTypePSEActive === 1 && pse" :class="{inactive: activeCredit == true, rounded: activeCredit == null, bgByDefaultButtons : !paymentType}" @click="selectPayment(2); activeCredit = false;">
        <p class="column-title" :class="{'black-tittle': validateTitle}"> {{ $t('debitCard') }} </p>
        <img src="img/PSE.png" class="img-card pse-img">
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
export default {
  props: ['pse'],
  data () {
    return {
      activeCredit: null
    };
  },
  computed: {
    ...mapGetters({
      paymentType: whiteLabelTypes.getters.paymentType
    })
  },
  mounted () {
    if (this.params.paymentTypeActive === 1 && this.params.paymentTypePSEActive === 0) {
      this.activeCredit = true;
    }
    if (this.params.paymentTypeActive === 0 && this.params.paymentTypePSEActive === 1) {
      this.activeCredit = false;
    }
  },
  methods: {
    selectPayment (type) {
      this.$store.commit(whiteLabelTypes.mutations.setPaymentType, type);
    },
    validateTitle () {
      if (this.params.paymentType === 'MP' || this.design.color1.toLowerCase() === '#fff' || this.design.color1.toLowerCase() === '#ffffff') {
        return true;
      }
      return false;
    }
  }
};
</script>
<style scoped lang="scss">
.metohpayment {
  padding: 0;
}
.columns-container{
  display: flex;
  gap: 2px;
  .column.btn-payment{
    padding: 9.4px;
  }
  &.mp-background{
    .btn-payment{
      padding: 11px;
      border: 1px solid #d6d6d6;
    }
  }
}
.column-title{
  font-size: 14px;
  font-weight: 600;
  color: var(--color1);
  &.black-tittle{
    color: #444b57;
  }
}
.btn-payment {
  border: 1px solid var(--bgColorStepper)!important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 20px;
  &.bgByDefaultButtons{
    background: var(--bgColorStepper)!important;
    .column-title{
      color: var(--bgColorCheckout)!important;
    }
  }
  @media screen and (max-width: 768px){
    gap: 10px;
  }
  &:not(.rounded){
    background-color: var(--bgColorStepper);
    .column-title{
      color: var(--bgColorCheckout);
    }
  }
  &.inactive{
    background-color: var(--bgColorStepper);
    border-bottom: 0!important;
    opacity: 0.5;
    .column-title{
      color: var(--bgColorCheckout)!important;
    }
  }
  &.rounded{
    background-color: var(--bgColorCheckout);
    .column-title{
      color: var(--colorCheckout);
    }
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .card-images{
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
.img-card {
  /* height: 0.75rem; */
  &.visa-img, &.master-img{
    height: auto;
  }
  &.visa-img{
    width: 50.38px;
    filter: drop-shadow(0 1px 3px rgba(255, 255, 255, 0.50));
  }
  &.master-img{
    width: 26px;
  }
  &.diners-img, &.master-img{
    filter: drop-shadow(0 1px 3px rgba(255, 255, 255, 0.60));
  }
  &.diners-img{
    width: 50px;
    height: auto;
  }
  &.american-img{
    width: 20px;
    height: auto;
    filter: drop-shadow(1px 1px 3px rgba(255, 255, 255, 0.60));
  }
  &.pse-img{
    width: 21px;
    height: auto;
    filter: drop-shadow(1px 1px 3px rgba(255, 255, 255, 0.60));
  }
}
.column.btn-payment:not(.inactive){
  .pse-img{
    width: 21px;
    height: auto;
    filter: drop-shadow(0 1px 3px rgba(255, 255, 255, 0.60));
  }
}
@media screen and (max-width: 768px) {
  .btn-payment{
    flex-wrap: wrap;
    gap: 0;
    .column-title{
      width: 100%;
      text-align: center;
    }
    .card-images{
      gap: 15px;
    }
  }
}

@media screen and (max-width: 414px) {
  .btn-payment{
    .card-images{
      gap: 5px;
    }
  }
}
</style>
