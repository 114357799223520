<template>
    <span v-if="reservationPomboTexto.typeReservation === 'Pombo'">
        <span>
        <i class="fa fa-exclamation-circle"></i>&nbsp;
        <b>{{$t('breakfastShow')}}.</b>
        </span>
    </span>
</template>
<script>
export default {
  props: ['reservationPomboTexto']
};
</script>
