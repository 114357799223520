<template>
  <div>
    <div class="paymentCard-wompi">
      <form @submit.prevent="validateBeforeCard" class="paymentCard-wompi-form">
        <div class="paymentCard-wompi-form__container-data" v-if="loadedToken">
          <h3 class="title-type">{{$t('payerDetails')}}</h3>
          <div class="paymentCard-wompi-form__container-data__fields paymentCard-wompi-form__container-data__fields__credit-card-wompi">
            <b-field
              :message="errors.first('name')"
              :type="{'is-danger': errors.has('name')}"
              class="paymentCard-wompi-form__container-data__fields__credit-card-wompi__name"
            >
              <b-input
                :placeholder="$t('cardholder')"
                autofocus
                icon
                icon-pack="fa"
                id="name"
                name="name"
                type="text"
                v-model.trim="card.card_holder"
                v-validate="'required|alpha_spaces|min:5'"
              ></b-input>
            </b-field>
            <span class="field-custom-validation-wompi aux-document-field">
              <b-field
                :message="errors.first('identification')"
                :type="{'is-danger': errors.has('identification')}"
                class="paymentCard-wompi-form__container-data__fields__credit-card-wompi__document-number"
                :class="{'padding-aux' : validateDoc}"
              >
                <InputC
                  :options="identificationTypes"
                  :placeHolder="$t('idNumber')"
                  :valueInput="'' + card.identification"
                  @valueChange="changedValueIdentity"
                  :start="identificationTypes[0].value"
                  selector
                />
                <p class="help is-danger" v-if="validateDoc">{{validateDoc}}</p>
              </b-field>
              <span class="icon is-right has-text-danger custom-validation-danger" v-if="validateDoc"><i class="fa fa-exclamation-circle"></i></span>
            </span>

            <b-field
              :message="errors.first('email')"
              :type="{'is-danger': errors.has('email')}"
              class="paymentCard-wompi-form__container-data__fields__credit-card-wompi__email"
            >
              <b-input
                :placeholder="$t('email')"
                autofocus
                icon
                icon-pack="fa"
                id="email"
                name="email"
                type="text"
                v-model="card.email"
                v-validate="'required|email'"
              ></b-input>
            </b-field>
          </div>
          <h3 class="title-type">{{$t('cardData')}}</h3>
          <div class="paymentCard-wompi-form__container-data__fields paymentCard-wompi-form__container-data__fields__credit-card-wompi">
              <b-field class="paymentCard-wompi-form__container-data__fields__credit-card-wompi__card-number"
                :message="errors.first('card')"
                :type="{'is-danger': errors.has('card')}"
                :class="{'padding-aux' : validateCardNumber}"
              >
                <b-input
                  :maxlength="maxNumbers"
                  autofocus
                  expanded
                  icon
                  icon-pack="fa"
                  id="card"
                  name="card"
                  placeholder="xxxx xxxx xxxx xxxx"
                  type="text"
                  v-mask="['#### #### #### #####']"
                  v-model="card.number"
                  v-validate="'required|min:14|max:21'"
                ></b-input>
              </b-field>

            <span class="field-custom-validation-wompi aux-card-month-field">
              <b-field
                :message="errors.first('mes')"
                :type="{'is-danger': errors.has('mes')}"
                class="paymentCard-wompi-form__container-data__fields__credit-card-wompi__month"
              >
                <Selector
                  :options="mes"
                  :required="required"
                  :placeHolder="$t('giftCardView.payment.text26')"
                  @change="changedSelectMes"
                  @value="(e) => {
                    card.exp_month = e;
                    validateCardMonth = null;
                  }"
                />
                <p class="help is-danger" v-if="validateCardMonth">{{validateCardMonth}}</p>
              </b-field>
            </span>
            <span class="field-custom-validation-wompi aux-card-year-field">
              <b-field
                :message="errors.first('ano')"
                :type="{'is-danger': errors.has('ano')}"
                class="paymentCard-wompi-form__container-data__fields__credit-card-wompi__year"
              >
                <Selector
                  :options="ano"
                  :required="required"
                  :placeHolder="$t('giftCardView.payment.text27')"
                  @change="changedSelectAno"
                  :start="''"
                  @value="(e) => {
                    card.exp_year = e;
                    validateCardYear = null;
                  }"
                />
                <p class="help is-danger" v-if="validateCardYear">{{validateCardYear}}</p>
              </b-field>
            </span>
            <span class="field-custom-validation-wompi aux-card-quota-field">
              <b-field
                :message="errors.first('cuota')"
                :type="{'is-danger': errors.has('cuota')}"
                class="paymentCard-wompi-form__container-data__fields__credit-card-wompi__quotas"
              >
                <Selector
                  :options="cuota"
                  :required="required"
                  :placeHolder="$t('giftCardView.payment.text28')"
                  @change="changedSelectCuota"
                  @value="(e) => {
                    card.installments = e;
                    validateCardQuota = null;
                  }"
                />
                <p class="help is-danger" v-if="validateCardQuota">{{validateCardQuota}}</p>
              </b-field>
            </span>
            <b-field
              :message="errors.first('cvc')"
              :type="{'is-danger': errors.has('cvc')}"
              class="paymentCard-wompi-form__container-data__fields__credit-card-wompi__cvc"
              :class="{'padding-aux' : validateCardCVC}"
            >
              <b-input
                expanded
                icon
                icon-pack="fa"
                placeholder="CVC"
                maxlength="4"
                v-model="card.cvc"
                name="cvc"
                v-validate="'required|min:3|max:4'"
              ></b-input>
            </b-field>
          </div>
          <div class="paymentCard-wompi-form__container-data__logo-container">
            <div
              :class="{'padding-500':!reservation.isFree}"
              class="bottom-text-r"
              style="margin-right: 0px;"
              v-if="!reservation.isFree"
            >
              <div>{{$t('securePayments')}}</div>
              <div class="logo-fix-container">
                <i class="img-wompi-svg"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="paymentCard-wompi-form__footer-wompi-terms">
          <div :class="{'pay': !reservation.isFree , 'free': reservation.isFree }" class="terms">
            <div class="copy-terms">
              <b-checkbox class="terms-label" v-model="authTerms"></b-checkbox>
              <div>
                {{$t('termsReservationPay1')}}
                <a
                  @click="showTerms2 = true"
                  class="link-terms"
                >{{$t('termsAndCondiW')}}</a>
                {{$t('termsReservationPay2')}}
                <a
                  @click="showTerms = true"
                  class="link-terms"
                >{{$t('privacyPolicyW')}}*</a>
                .
                {{$t('termsReservationPay3')}}
              </div>
            </div>
          </div>
          <div :class="{'pay': !reservation.isFree , 'free': reservation.isFree }" class="terms">
            <div class="copy-terms">
              <b-checkbox class="terms-label" v-model="authTermsWompi"></b-checkbox>
              <div>
                {{$t('termsPaymentWompi')}}
                <a
                  :href="linkTerms"
                  target="_blank"
                  class="link-terms"
                >{{$t('termsPaymentWompi1')}}</a>
                {{$t('termsPaymentWompi2')}}
              </div>
            </div>
          </div>
          <div id="modal-terms" v-if="showTerms">
            <b-modal :active.sync="showTerms">
              <div class="terms-body">
                <h3 class="title-modal">{{ $t('checkoutView.components.text15') }}</h3>
                <terms :fecha="'22 de Noviembre del 2019'"></terms>
              </div>
            </b-modal>
          </div>
          <div id="modal-terms" v-if="showTerms2">
            <b-modal :active.sync="showTerms2">
              <div class="terms-body">
                <h3 class="title-modal">{{ $t('checkoutView.components.text16') }}</h3>
                <terms2 :fecha="'22 de Noviembre del 2019'"></terms2>
              </div>
            </b-modal>
          </div>
          <div class="row">
            <button
              :class="{
                'opacity06':(card.card_holder === null || card.card_holder === ''||
                  card.identification === null || card.identification === '' ||
                  card.number === null || card.number === '' ||
                  card.exp_month === null || card.exp_month === '' ||
                  card.exp_year === null || card.exp_year === '' || card.installments === null || card.installments === ''
                  || card.cvc === null || card.cvc === ''
                  || (errors.items.length > 0))}"
              :disabled="(loader || !authTerms || !authTermsWompi)"
              :style="{backgroundColor:design.btnBgReservationFooter, color: design.btnColorReservationFooter+'!important'}"
              class="btn btn-reserva-disabled"
              type="submit"
            >{{ card.checked ? $t('addCardPay') : $t('pay') }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import globalTypes from '@/store/types/global';
import terms from '@/components/Terms';
import terms2 from '@/components/Terms2';
import { mapGetters, mapActions } from 'vuex';
import InputC from '@/components/customInput.vue';
import Selector from '@/components/Select/GeneralSelector.vue';

export default {
  components: { terms, terms2, InputC, Selector },
  props: ['showMessageCountry', 'btnCheckedMainPlate'],
  data () {
    return {
      authTerms: true,
      showTerms: false,
      showTerms2: false,
      maxNumbers: 19,
      cardType: '',
      loadedToken: false,
      linkTerms: '',
      acceptanceToken: '',
      authTermsWompi: true,
      card: {
        card_holder: null,
        cvc: null,
        exp_month: null,
        exp_year: null,
        number: null,
        installments: null,
        email: null,
        identification: null
      },
      mes: Array.from({ length: 12 }, (v, k) => {
        if (k < 9) return { name: '0' + (k + 1), value: '0' + (k + 1) };
        return { value: k + 1, name: k + 1 };
      }),
      ano: [],
      cuota: Array.from({ length: 36 }, (v, k) => {
        return { name: k + 1, value: k + 1 };
      }),
      elapsedWaitingWompi: 0,
      identificationTypes: [{ name: 'C.C.', value: 'CC' }],
      validateDoc: null,
      validateCardNumber: null,
      validateCardMonth: null,
      validateCardYear: null,
      validateCardQuota: null,
      validateCardCVC: null
    };
  },
  computed: {
    ...mapGetters({
      reservation: reservationTypes.getters.reservationsUser,
      loader: whiteLabelTypes.getters.loader,
      stateRequiredExperience: whiteLabelTypes.getters.getStateRequiredException,
      tip: reservationTypes.getters.getTip,
      systemDate: globalTypes.getters.serverTime
    })
  },
  mounted () {
    this.$emit('validateCheck');
    const { data } = this.getYears(this.systemDate);
    for (let i = 0; i < data.length; i++) {
      // Guarda el objeto actual en una variable para mayor claridad
      const objeto = data[i];
      // Cambia el nombre de la propiedad 'label' por 'name'
      objeto.name = objeto.label;
      delete objeto.label; // Elimina la propiedad 'label' original
    }
    this.ano = data;
    if (this.showMessageCountry) {
      this.$buefy.snackbar.open({
        duration: 5000,
        message:
          "<i class='fa fa-exclamation-circle alerticon'></i>Debe seleccionar un país como indicativo, en los datos del cliente",
        type: 'is-warning',
        position: 'is-bottom',
        actionText: 'X',
        queue: false
      });
    }
    this.getAcceptanceToken()
      .then(({ data }) => {
        this.linkTerms = data.data.permalink;
        this.acceptanceToken = data.data.acceptance_token;
      })
      .finally(() => {
        this.loadedToken = true;
      });
    this.getIdentification();
  },
  watch: {
    card: {
      handler () {
        if (this.card.card != null) {
          const cardJustNumber = this.card.card.split(/\s+/).join('');
          const regexDiner = new RegExp(/^3(?:0[0-5]|[68][0-9])[0-9]{5,}$/);
          const regexVisa = new RegExp(/^4[0-9]{6,}$/);
          const regexAmex = new RegExp(/^3[47][0-9]{5,}$/);
          const regexMasterCard = new RegExp(/^5[1-5][0-9]{14}$/);

          if (regexVisa.test(cardJustNumber)) {
            this.cardType = 'Visa';
          } else if (regexAmex.test(cardJustNumber)) {
            this.cardType = 'Amex';
          } else if (regexMasterCard.test(cardJustNumber)) {
            this.cardType = 'MasterCard';
          } else if (regexDiner.test(cardJustNumber)) {
            this.maxNumbers = 21;
            this.cardType = 'Diners';
          } else {
            this.cardType = '';
            this.maxNumbers = 19;
          }
        }
      },
      deep: true
    },
    authTerms () {
      this.$emit('validateCheck', this.authTerms);
    },
    authTermsWompi () {
      this.$emit('validateCheck', this.authTermsWompi);
    }
  },
  methods: {
    ...mapActions({
      confirmReservation: reservationTypes.actions.confirmReservation
    }),
    selectPayment () {
      this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
    },
    customValidationsBeforeSubmit () {
      if (this.card.identification === null || this.card.identification === '' || this.card.identification === undefined) {
        this.validateDoc = this.$t('checkoutValidations.documentNumber.required');
      } else {
        this.validateDoc = null;
      }
      if (this.card.number === null || this.card.number === '' || this.card.number === undefined) {
        this.validateCardNumber = this.$t('checkoutValidations.cardNumber.required');
      } else {
        this.validateCardNumber = null;
      }
      if (this.card.exp_month === null || this.card.exp_month === '') {
        this.validateCardMonth = this.$t('checkoutValidations.field.required');
      } else {
        this.validateCardMonth = null;
      }
      if (this.card.exp_year === null || this.card.exp_year === '') {
        this.validateCardYear = this.$t('checkoutValidations.field.required');
      } else {
        this.validateCardYear = null;
      }
      if (this.card.installments === null || this.card.installments === '') {
        this.validateCardQuota = this.$t('checkoutValidations.field.required');
      } else {
        this.validateCardQuota = null;
      }
      if (this.card.cvc === null || this.card.cvc === '') {
        this.validateCardCVC = this.$t('checkoutValidations.field.required');
      } else {
        this.validateCardCVC = null;
      }
    },
    validateBeforeCard () {
      this.$validator.validateAll().then(result => {
        this.enableValidations = true;
        this.customValidationsBeforeSubmit();
        if (this.validateDoc === null && this.validateCardNumber === null) {
          if (result) {
            const applyStrong = window.localStorage.getItem('applyStrong');
            if (applyStrong) {
              if (!this.btnCheckedMainPlate && applyStrong === true) {
                this.$buefy.snackbar.open({
                  duration: 5000,
                  message:
                    "<i class='fa fa-exclamation-circle alerticon'></i>Es necesario que selecciones el plato fuerte de cada persona para poder completar la reserva",
                  type: 'is-warning',
                  position: 'is-bottom',
                  actionText: 'X',
                  queue: false
                });
                return;
              }
            }
            if ((this.params.activeExperiencies === 1 && this.params.optionRequiredExperiencie === 1) && (!this.reservation.experiences || this.reservation.experiences.length === 0 || this.reservation.experiences === undefined || this.reservation.experiences == null) && !this.reservation.isEvent && window.localStorage.getItem('_invalid_alert_experience') === undefined) {
              this.$buefy.snackbar.open({
                duration: 6000,
                message: `<div class="custom_alert"><div class="custom_alert_icon"><i class="icon_alert_danger"></i></div><div><p>Es necesario que selecciones la experiencia de cada persona para poder completar la reserva</p></div></div>`,
                type: 'is-danger is-custom',
                position: 'is-top',
                actionText: ' ',
                queue: true
              });
              return;
            }
            this.card.expiration = this.card.ano + '/' + this.card.mes;
            this.proccessCard();
          }
        }
      });
    },
    proccessCard () {
      if (
        this.reservation.phone === '' ||
        this.reservation.phone === null ||
        !this.reservation.phone
      ) {
        this.$emit('edit-phone', true);
      } else if (this.showMessageCountry) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message:
            "<i class='fa fa-exclamation-circle alerticon'></i>Debe seleccionar un país como indicativo, en los datos del cliente",
          type: 'is-warning',
          position: 'is-bottom',
          actionText: 'X',
          queue: false
        });
      } else {
        let dataBrowser = this.userAgentBrowser().parsedResult;
        this.reservation.dataBrowser = dataBrowser;
        this.reservation.balance = (this.reservation.balance + this.tip);
        this.reservation.totalBalance = (this.reservation.totalBalance + this.tip);
        this.reservation.tip = this.tip;
        this.$store.commit(globalTypes.mutations.setVerifyingPayment, true);
        this.confirmReservation({
          reservation: this.reservation,
          cardCredit: this.card,
          paymentProvider: 'wompi',
          'acceptance_token': this.acceptanceToken
        })
          .then(({ data }) => {
            if (data.code === 200 && data.waitResponse) {
              this.longPollingTransaction(data.transactionId);
              return;
            }

            if (data.code === 200) {
              this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
              this.$store.commit(reservationTypes.mutations.setSelectedZone, false);
              this.$store.commit(reservationTypes.mutations.setSelectedTable, null);
              this.$store.commit(globalTypes.mutations.setVerifyingPayment, false);
              this.reservation.infoId = data.id;
              window.localStorage.setItem('_reservation', btoa(JSON.stringify(this.reservation)));
              this.$store.commit(reservationTypes.mutations.setReservationsUser);
              window.localStorage.setItem('_reservationOk', true);
              this.$store.commit(
                whiteLabelTypes.mutations.setShowModalReservationConfirm
              );
              window.location.href = '/confirmation';
            }
            if (data.code === 705 || data.code === 799) {
              this.$emit('cancelTransactionCard', data.message);
              this.$store.commit(globalTypes.mutations.setVerifyingPayment, false);
            }
          })
          .catch(() => {
            this.$store.commit(globalTypes.mutations.setVerifyingPayment, false);
            this.$buefy.dialog.alert({
              message: "<i class='fa fa-exclamation-circle alerticon'></i>Ocurrio un error al procesar la petición con la pasarela de pago <br><strong>Intenta realizar el pago de nuevo</strong> ",
              confirmText: 'Aceptar',
              onConfirm: () => {
                window.location.reload();
              }
            });
          });
        if (this.reservation.isEditWhiteLabel != null) {
          this.reservation.isEditWhiteLabel = null;
        }
      }
    },
    changedSelectMes (selected) {
      this.card.exp_month = selected;
    },
    changedSelectAno (selected) {
      this.card.exp_year = selected;
    },
    changedSelectCuota (selected) {
      this.card.installments = selected;
    },
    getAcceptanceToken () {
      return this.$http.get('/payment/wompi/acceptance-token/' + this.vendorId);
    },
    longPollingTransaction (transactionId) {
      this.$http.get('/payment/status-transaction?paymentProvider=wompi&transactionId=' + transactionId)
        .then(({ data }) => {
          if (data.status === 'APPROVED') {
            this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
            this.$store.commit(reservationTypes.mutations.setSelectedZone, false);
            this.$store.commit(reservationTypes.mutations.setSelectedTable, null);
            this.$store.commit(globalTypes.mutations.setVerifyingPayment, false);
            this.reservation.infoId = data.id;
            window.localStorage.setItem('_reservation', btoa(JSON.stringify(this.reservation)));
            this.$store.commit(reservationTypes.mutations.setReservationsUser);
            window.localStorage.setItem('_reservationOk', true);
            this.$store.commit(
              whiteLabelTypes.mutations.setShowModalReservationConfirm
            );
            window.location.href = '/confirmation';
          }
          if (data.status === 'DECLINED' || data.status === 'ERROR') {
            this.$emit('cancelTransactionCard', data.message);
          }
          this.$store.commit(globalTypes.mutations.setVerifyingPayment, false);
        }).catch(() => {
          setTimeout(() => {
            this.longPollingTransaction(transactionId);
          }, 700);
        });
    },
    changedValueIdentity (values) {
      if (this.enableValidations) {
        if (values.inputValue !== undefined && values.inputValue !== null && values.inputValue !== '') {
          this.validateDoc = null;
        } else {
          this.validateDoc = this.$t('checkoutValidations.documentNumber.required');
        }
      }
      this.card.identification = values.inputValue;
    },
    getIdentification () {
      this.$http.get('payment/identifications/' + this.vendor.id).then(({ data }) => {
        if (data.code === 200) {
          this.identificationTypes = data.data.map((i) => {
            return {
              name: i.name,
              value: i.id
            };
          });
        }
      });
    }
  }
};
</script>
<style lang="scss">
  @import "@/assets/styles/payment/_paymentCardWompi.scss";
</style>
