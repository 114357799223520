<template>
  <div v-if="reservationInfo.typeReservation === 'Pombo'">
    <div class="flex-container" v-if="reservationInfo.typeReservation === 'Pombo'">
      <span class="item-left-total">
        <span class="checkout-item-subtitle">{{ $t('prePurchase') }}</span>
        <p class="item-small-total">
          {{reservationInfo.payDetails.adult | currency("$ ", 0, { thousandsSeparator: "," })}}
          * x{{ reservationInfo.adult }} pax
        </p>
      </span>
      <p class="item-right-total">
        {{ (reservationInfo.payDetails.adult * reservationInfo.adult) | currency("$ ", 0, { thousandsSeparator: "," }) }}
      </p>
    </div>

    <div class="flex-container" v-if="reservationInfo.typeReservation === 'Pombo' && reservationInfo.boy > 0">
      <span class="item-left-total">
        <span class="checkout-item-subtitle">{{ $t('prePurchase') }}</span>
        <p class="item-small-total">
          {{ reservationInfo.payDetails.boy | currency("$ ", 0, { thousandsSeparator: "," }) }}
          * {{ reservationInfo.boy }} {{ $t("kids") }}
        </p>
      </span>
      <p class="item-right-total">
        {{ (reservationInfo.payDetails.boy * reservationInfo.boy) | currency("$ ", 0, { thousandsSeparator: "," }) }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ['reservationInfo']
};
</script>
