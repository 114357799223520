<template>
    <span
        class="item-final-check"
        v-if="reservationGeneralTexto.typeReservation === 'Rumba' || reservationGeneralTexto.typeReservation === 'Cena' || reservationGeneralTexto.typeReservation === 'Brunch'"
    >
        <div>
            <i class="fa fa-exclamation-circle"></i>
            &nbsp;{{$t('contributionArtist1')}}
            <b
            class="fw-700"
            >&nbsp;{{$t('contributionArtist2')}}</b>
            {{$t('contributionArtist3')}}
            <b
            class="fw-700"
            >&nbsp;{{$t('contributionArtist4')}}</b>.
        </div>
    </span>
</template>
<script>
export default {
  props: ['reservationGeneralTexto']
};
</script>
