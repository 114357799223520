<template>
  <b-modal :active.sync="value" :can-cancel="false" class="ModalTransaction">
    <div class="modals-bosy">
      <div class="col-12 text-center icon-padding">
        <IconTemporal fill="#d99332" width="20em"></IconTemporal>
      </div>
      <div>
        <h3 class="title-modal">{{ $t('checkoutView.components.text31') }}</h3>
        <div class="columns is-mobile">
          <div class="column item-l">
            <iconPeople fill="#000" width="4em"></iconPeople>
            &nbsp;
            {{reservation.people}}
          </div>
          <div class="column item-l">
            <iconCalendar fill="#000" height="25px" width="25px"></iconCalendar>
            &nbsp;
            {{reservation.dateTime | moment('timezone', vendorTimezone, 'DD / MMM')}}
          </div>
          <div class="column item-l">
            <iconTime fill="#000" height="25px" width="25px"></iconTime>
            &nbsp;
            {{reservation.dateTime | moment('timezone', vendorTimezone, 'hh : mm A')}}
          </div>
        </div>
        <div class="col-12 text-lef-messages">
          <h5 class="title-confirm">{{ $t('checkoutView.components.text32') }}</h5>
          <p>{{ $t('checkoutView.components.text33') }}</p>
          <b class="final-message">{{ $t('checkoutView.components.text29') }}</b>
          <br />
        </div>
        <div class="btns-modal">
          <button
            :class="{'full-w':reservation.isFree }"
            @click="goAccount()"
            class="full-btn-modal btn-modal-fill"
            type="button"
          >&nbsp; {{$t('continue')}}</button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import iconPeople from '@/components/Icons/iconPeople';
import iconTime from '@/components/Icons/iconTime';
import IconTemporal from '@/components/Icons/iconTemporal';
import iconCalendar from '@/components/Icons/iconCalendar';

export default {
  props: ['value', 'reservation'],
  components: { IconTemporal, iconCalendar, iconTime, iconPeople },
  methods: {
    goAccount () {
      localStorage.removeItem('_reservation');
      localStorage.removeItem('_reservationOk');
      this.$emit('input', false);
      this.$router.replace('account');
    }
  }
};
</script>
<style lang="scss" scoped>
.ModalTransaction {
  .icon-padding {
    margin-bottom: 20px;
  }
  .modals-bosy {
    .btns-modal {
      margin-top: 15px !important;
      flex-direction: column !important;
      button {
        width: 100% !important;
        padding: 10px !important;
        margin: auto !important;
        text-align: center;
        margin-top: 10px !important;
      }
    }
  }
}
</style>
