<template>
  <div>
    <div class="paymentCard-mp" >
      <form @submit.prevent="validateBeforeCard" id="pay" name="pay" class="form-mp-credit">
        <input
          id="transactionAmount"
          name="transactionAmount"
          type="hidden"
          v-model="card.net_amount"
        />
        <h3 class="title-type">{{$t('payerDetails')}}</h3>
        <section class="fields-container person-data-fields">
          <!-- Card holder Name -->
          <b-field class="person-name-option"
            :message="errors.first('cardholderName')"
            :type="{'is-danger': errors.has('cardholderName')}"
          >
            <b-input
              data-checkout="cardholderName"
              icon
              icon-pack="fa"
              autocomplete="off"
              id="cardholderName"
              name="cardholderName"
              :placeholder="$t('cardholder')"
              type="text"
              v-model="card.payer.first_name"
              v-validate="'required|min:2'"
              required
            ></b-input>
          </b-field>

          <!-- Identification type -->
          <span class="field-custom-validation-mp aux-w">
            <b-field class="document-option" v-if="identification.length > 0" :class="{'padding-aux' : validateDoc}">
              <InputC
                :required="true"
                :options="identification"
                :placeHolder="$t('documentNumber')"
                :valueInput="'' + card.payer.identification.number"
                @valueChange="changedValueIdentity"
                @blurInput="validateIdentityType"
                :start="identification[0].value"
                selector
              />
              <p class="help is-danger" v-if="validateDoc">{{validateDoc}}</p>
            </b-field>
            <span class="icon is-right has-text-danger custom-validation-danger" v-if="validateDoc"><i class="fa fa-exclamation-circle"></i></span>
          </span>

          <!-- Email -->
          <b-field class="email-option"
            :message="errors.first('email')"
            :type="{'is-danger': errors.has('email')}"
          >
            <b-input
              :placeholder="$t('email')"
              icon
              icon-pack="fa"
              autocomplete="off"
              id="email"
              name="email"
              type="email"
              v-model="card.payer.email"
              v-validate="'required|email'"
            ></b-input>
          </b-field>
        </section>

        <h3 class="title-type">{{$t('cardData')}}</h3>
        <section class="fields-container">
          <!-- Card Number -->
          <span class="field-custom-validation-mp aux-w">
            <b-field class="mp-fields card-number-option"
              :message="errors.first('cardNumber')"
              :type="{'is-danger': errors.has('cardNumber')}"
              :class="{'padding-aux-3' : validateCardNumber}"
            >
              <div class="mp-fields-item" id="cardNumber"></div>
              <p class="help is-danger white-spaces-aux" v-if="validateCardNumber">{{validateCardNumber}}</p>
            </b-field>
            <span class="icon is-right has-text-danger custom-validation-danger" v-if="validateCardNumber"><i class="fa fa-exclamation-circle"></i></span>
          </span>

          <!-- Security Code -->
          <span class="field-custom-validation-mp aux-w">
            <b-field class="mp-fields security-code-option"
              :message="errors.first('securityCode')"
              :type="{'is-danger': errors.has('securityCode')}"
              :class="{'padding-aux-3' : validateCardCVC}"
            >
              <div class="mp-fields-item" id="securityCode"></div>
              <p class="help is-danger white-spaces-aux" v-if="validateCardCVC">{{validateCardCVC}}</p>
            </b-field>
            <span class="icon is-right has-text-danger custom-validation-danger" v-if="validateCardCVC"><i class="fa fa-exclamation-circle"></i></span>
          </span>

          <!-- Expiration Month -->
          <span class="field-custom-validation-mp aux-w">
            <b-field class="mp-fields expiration-month-option"
              :message="errors.first('cardExpirationMonth')"
              :type="{'is-danger': errors.has('cardExpirationMonth')}"
              :class="{'padding-aux-3' : validateCardMY}"
            >
              <div class="mp-fields-item" id="expirationDate"></div>
              <p class="help is-danger white-spaces-aux" v-if="validateCardMY">{{validateCardMY}}</p>
            </b-field>
            <span class="icon is-right has-text-danger custom-validation-danger" v-if="validateCardMY"><i class="fa fa-exclamation-circle"></i></span>
          </span>

          <!-- Cuotas -->
          <b-field class="options-select-arrow cuotas-option"
            :message="errors.first('cuota')"
            :type="{'is-danger': errors.has('cuota')}"
          >
            <b-select
              :placeholder="$t('prepagoView.text28')"
              expanded
              name="cuota"
              v-model="card.installments"
              v-validate="'required'"
            >
              <option
                :key="'cuota#' + y"
                :value="cuota.value"
                v-for="(cuota, y) in cuotas"
              >{{ cuota.label }}</option>
            </b-select>
          </b-field>
        </section>

        <footer class="payment-footer flex-end">
          <!-- Save Card -->
          <div class="furute-box" v-if="false">
            {{$t('saveCard')}}
            <b-switch
              :value="true"
              checked="true"
              id="saveCard"
              name="saveCard"
              type="is-success"
              v-model="checked"
            ></b-switch>
          </div>

          <!-- Secure Payment -->
          <div class="secure-payment">
            <div>
              {{$t('securePayments')}} &nbsp;
            </div>
            <img src="img/mercadoPago.svg" />
          </div>
        </footer>
      </form>
    </div>
    <section class="confirmation-section">
      <div :class="{'pay': !reservation.isFree , 'free': reservation.isFree }" class="terms">
        <div class="copy-terms">
          <b-checkbox class="terms-label" v-model="authTerms"></b-checkbox>
          &nbsp;{{$t('termsReservationPay1')}}
          <a
            @click="showTerms2 = true"
            class="link-terms"
          >{{$t('termsAndCondiW')}}</a>
          {{$t('termsReservationPay2')}}
          <a
            @click="showTerms = true"
            class="link-terms"
          >{{$t('privacyPolicyW')}}*</a>
          {{$t('termsReservationPay2Aux')}}.
        </div>
      </div>
      <div id="modal-terms">
        <b-modal :active.sync="showTerms">
          <div class="terms-body">
            <h3 class="title-modal">{{ $t('checkoutView.components.text15') }}</h3>
            <terms :fecha="'22 de Noviembre del 2019'"></terms>
          </div>
        </b-modal>
      </div>
      <div id="modal-terms">
        <b-modal :active.sync="showTerms2">
          <div class="terms-body">
            <h3 class="title-modal">{{ $t('checkoutView.components.text16') }}</h3>
            <terms2 :fecha="'22 de Noviembre del 2019'"></terms2>
          </div>
        </b-modal>
      </div>
      <div class="btn-pay-container">
        <button @click="validateBeforeCard"
          :class="{'opacity06':(card.payer.first_name === null || card.payer.first_name === ''||
              card.payer.identification.type === null || card.payer.identification.type === '' ||
              card.payer.identification.number === null || card.payer.identification.number === '' ||
              card.payer.email === null || card.payer.email === '' ||
              card.installments === null || card.installments === '' || (errors.items.length > 0))}"
          :disabled="(loader || !authTerms)"
          class="btn-pay"
          type="submit"
        >{{ card.checked ? $t('addCardPay') : $t('pay') }}</button>
      </div>
    </section>
  </div>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import globalTypes from '@/store/types/global';
import reservationTypes from '@/store/types/reservation';
import terms from '@/components/Terms';
import terms2 from '@/components/Terms2';
import { mapGetters, mapActions } from 'vuex';
import Cleave from 'cleave.js';
import InputC from '@/components/customInput.vue';

const cleave = {
  name: 'cleave',
  bind (el, binding) {
    const input = el.querySelector('input');
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind (el) {
    const input = el.querySelector('input');
    input._vCleave.destroy();
  }
};
export default {
  components: { terms, terms2, InputC },
  props: ['showMessageCountry', 'btnCheckedMainPlate'],
  directives: { cleave },
  data () {
    return {
      masks: {
        creditCard: { creditCard: true },
        custom: {
          numericOnly: true
        }
      },
      authTerms: true,
      showTerms: false,
      showTerms2: false,
      cvv: null,
      cardNumber: null,
      mes: null,
      ano: null,
      checked: true,
      doSubmit: false,
      maxNumber: 19,
      card: {
        payer: {
          first_name: null,
          identification: {
            type: null,
            number: null
          },
          email: null
        },
        token: null,
        installments: null,
        description: null,
        payment_method_id: null,
        transaction_amount: 0,
        net_amount: 0,
        binary_mode: true,
        taxes: [
          {
            value: 0,
            type: 'IVA'
          }
        ]
      },
      identification: [],
      meses: [
        { value: '01', label: '01' },
        { value: '02', label: '02' },
        { value: '03', label: '03' },
        { value: '04', label: '04' },
        { value: '05', label: '05' },
        { value: '06', label: '06' },
        { value: '07', label: '07' },
        { value: '08', label: '08' },
        { value: '09', label: '09' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
      ],
      anos: [],
      cuotas: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' }
      ],
      cuotasDefault: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' }
      ],
      mercadopago: null,
      validateDoc: null,
      validateCardNumber: null,
      validateCardCVC: null,
      validateCardMY: null,
      enableValidations: false
    };
  },
  computed: {
    ...mapGetters({
      reservation: reservationTypes.getters.reservationsUser,
      loader: whiteLabelTypes.getters.loader,
      stateRequiredExperience: whiteLabelTypes.getters.getStateRequiredException,
      tip: reservationTypes.getters.getTip,
      systemDate: globalTypes.getters.serverTime,
      integrations: [whiteLabelTypes.getters.integrations]
    })
  },
  mounted () {
    this.$emit('validateCheck');
    const { data } = this.getYears(this.systemDate);
    this.anos = data;
    if (this.showMessageCountry) {
      this.$buefy.snackbar.open({
        duration: 5000,
        message:
          "<i class='fa fa-exclamation-circle alerticon'></i>Debe seleccionar un país como indicativo, en los datos del cliente",
        type: 'is-warning',
        position: 'is-bottom',
        actionText: 'X',
        queue: false
      });
    }
    this.card.transaction_amount = this.reservation.balance;
    this.card.net_amount = this.reservation.balance;
    this.getIdentification();
    if (this.integrations.length > 0) {
      const mercadopago = this.integrations.find(int => int.type === 'mercadopago' && int.environment === process.env.NODE_ENV);
      if (mercadopago) {
        // eslint-disable-next-line
        this.mercadopago = new MercadoPago(this.decryptMx(mercadopago.login));
      } else {
        // eslint-disable-next-line
        this.mercadopago = new MercadoPago(process.env.VUE_APP_MERCADOPAGO);
      }
    } else {
      // eslint-disable-next-line
      this.mercadopago = new MercadoPago(process.env.VUE_APP_MERCADOPAGO);
    }
    this.setConfigMercadoPago();
  },
  methods: {
    ...mapActions({
      confirmReservation: reservationTypes.actions.confirmReservation
    }),
    setConfigMercadoPago () {
      const cardNumberElement = this.mercadopago.fields.create('cardNumber', {
        placeholder: 'Número de tarjeta'
      }).mount('cardNumber');
      this.mercadopago.fields.create('expirationDate', {
        placeholder: 'MM/YY'
      }).mount('expirationDate');
      this.mercadopago.fields.create('securityCode', {
        placeholder: 'Código de seguridad'
      }).mount('securityCode');
      cardNumberElement.on('binChange', this.getPaymentMethods);
    },
    async getPaymentMethods (data) {
      const { bin } = data;
      if (bin != null && bin !== undefined) {
        const { results } = await this.mercadopago.getPaymentMethods({ bin });
        await this.setPaymentMethod(results);
        await this.getInstallments(bin);
      } else {
        this.card.payment_method_id = null;
        this.maxNumber = 19;
        this.cuotas = this.cuotasDefault;
      }
    },
    async setPaymentMethod (response) {
      let paymentMethodId = response[0].id;
      this.card.payment_method_id = paymentMethodId;
    },
    async getInstallments (bin) {
      const installments = await this.mercadopago.getInstallments({
        amount: document.getElementById('transactionAmount').value,
        bin,
        paymentTypeId: 'credit_card'
      });
      let data = [];
      installments[0].payer_costs.forEach(installment => {
        let opt = {
          label: installment.recommended_message,
          value: installment.installments
        };
        data.push(opt);
      });
      this.cuotas = data;
    },
    setCardTokenAndPay (token) {
      let form = document.getElementById('pay');
      let card = document.createElement('input');
      card.setAttribute('name', 'token');
      card.setAttribute('type', 'hidden');
      card.setAttribute('value', token);
      form.appendChild(card);
      this.doSubmit = true;
      this.validateBeforeCard();
    },
    selectPayment () {
      this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
    },
    groupErrorsByField (errors) {
      const groupedErrors = {};
      errors.forEach(error => {
        const field = error.field;
        if (!groupedErrors[field]) {
          groupedErrors[field] = [];
        }
        groupedErrors[field].push(error.cause);
      });

      return groupedErrors;
    },
    validateMercadoPagoFields (errors) {
      const errorsValidations = this.groupErrorsByField(errors);
      if (errorsValidations.cardNumber && errorsValidations.cardNumber.length > 0) {
        if (errorsValidations.cardNumber[0] === 'invalid_value' || errorsValidations.cardNumber[0] === 'invalid_type') {
          this.validateCardNumber = this.$t('checkoutValidations.cardNumber.required');
        }
        if (errorsValidations.cardNumber[0] === 'invalid_length') {
          this.validateCardNumber = this.$t('checkoutValidations.cardNumber.max');
        }
      } else {
        this.validateCardNumber = null;
      }
      if (errorsValidations.expirationDate && errorsValidations.expirationDate.length > 0) {
        if (errorsValidations.expirationDate[0] === 'invalid_value' || errorsValidations.expirationDate[0] === 'invalid_type') {
          this.validateCardMY = this.$t('checkoutValidations.dateCard.required');
        }
        if (errorsValidations.expirationDate[0] === 'invalid_length') {
          this.validateCardMY = this.$t('checkoutValidations.dateCard.invalid');
        }
      } else {
        this.validateCardMY = null;
      }
      if (errorsValidations.securityCode && errorsValidations.securityCode.length > 0) {
        if (errorsValidations.securityCode[0] === 'invalid_value' || errorsValidations.expirationDate[0] === 'invalid_type') {
          this.validateCardCVC = this.$t('checkoutValidations.dateCard.required');
        }
        if (errorsValidations.securityCode[0] === 'invalid_length') {
          this.validateCardCVC = this.$t('checkoutValidations.dateCard.invalid');
        }
      } else {
        this.validateCardCVC = null;
      }
    },
    async validateBeforeCard () {
      let instance = this;
      this.$validator.validateAll().then(async (result) => {
        this.enableValidations = true;
        if (this.card.payer.identification.number === null || this.card.payer.identification.number === '') {
          this.validateDoc = this.$t('checkoutValidations.documentNumber.required');
        } else {
          if (!/^\d+$/.test(this.card.payer.identification.number)) {
            this.validateDoc = this.$t('checkoutValidations.documentNumber.number');
          } else {
            this.validateDoc = null;
          }
        }
        if (this.validateDoc === null) {
          if (result) {
            const applyStrong = window.localStorage.getItem('applyStrong');
            if (applyStrong) {
              if (!this.btnCheckedMainPlate && applyStrong === true) {
                this.$buefy.snackbar.open({
                  duration: 5000,
                  message:
                    "<i class='fa fa-exclamation-circle alerticon'></i>Es necesario que selecciones el plato fuerte de cada persona para poder completar la reserva",
                  type: 'is-warning',
                  position: 'is-bottom',
                  actionText: 'X',
                  queue: false
                });
                return;
              }
            }
            if ((this.params.activeExperiencies === 1 && this.params.optionRequiredExperiencie === 1) && (!this.reservation.experiences || this.reservation.experiences.length === 0 || this.reservation.experiences === undefined || this.reservation.experiences == null) && !this.reservation.isEvent && window.localStorage.getItem('_invalid_alert_experience') === undefined) {
              this.$buefy.snackbar.open({
                duration: 6000,
                message: `<div class="custom_alert"><div class="custom_alert_icon"><i class="icon_alert_danger"></i></div><div><p>Es necesario que selecciones la experiencia de cada persona para poder completar la reserva</p></div></div>`,
                type: 'is-danger is-custom',
                position: 'is-top',
                actionText: ' ',
                queue: true
              });
              return;
            }
            if (!instance.doSubmit) {
              try {
                const token = await this.mercadopago.fields.createCardToken({
                  cardholderName: document.getElementById('cardholderName').value,
                  identificationType: this.card.payer.identification.type,
                  identificationNumber: this.card.payer.identification.number
                });
                this.card.token = token.id;
                this.setCardTokenAndPay(token.id);
                this.validateCardNumber = null;
                this.validateCardMY = null;
                this.validateCardCVC = null;
                return false;
              } catch (error) {
                this.validateMercadoPagoFields(error);
              }
            } else {
              instance.proccessCard();
            }
          }
        }
      });
    },
    proccessCard () {
      if (
        this.reservation.phone === '' ||
        this.reservation.phone === null ||
        !this.reservation.phone
      ) {
        this.$emit('edit-phone', true);
      } else if (this.showMessageCountry) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message:
            "<i class='fa fa-exclamation-circle alerticon'></i>Debe seleccionar un país como indicativo, en los datos del cliente",
          type: 'is-warning',
          position: 'is-bottom',
          actionText: 'X',
          queue: false
        });
      } else {
        let dataBrowser = this.userAgentBrowser().parsedResult;
        this.reservation.dataBrowser = dataBrowser;
        this.reservation.paymentProvider = 'mp';
        this.reservation.balance = (this.reservation.balance + this.tip);
        this.reservation.tip = this.tip;
        this.reservation.totalBalance = (this.reservation.totalBalance + this.tip);
        this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
        this.confirmReservation({
          reservation: this.reservation,
          cardCredit: this.card,
          paymentProvider: 'mp'
        }).then(({ data }) => {
          if (data.code === 799) {
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
            this.$buefy.snackbar.open({
              duration: 5000,
              message:
                "<i class='fa fa-exclamation-circle alerticon'></i>" + data.msg,
              type: 'is-warning',
              position: 'is-bottom',
              actionText: 'X',
              queue: false
            });
            return;
          }
          if (data.code === 705) {
            this.$emit('cancelTransactionCard', data.message);
          }
          if (data.code === 780) {
            this.$emit('pendingTransactionCard', true);
          }
          if (data.code === 200) {
            this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
            this.$store.commit(reservationTypes.mutations.setSelectedZone, false);
            this.$store.commit(reservationTypes.mutations.setSelectedTable, null);
            this.reservation.infoId = data.id;
            window.localStorage.setItem('_reservation', btoa(JSON.stringify(this.reservation)));
            this.$store.commit(reservationTypes.mutations.setReservationsUser);
            window.localStorage.setItem('_reservationOk', true);
            this.$store.commit(
              whiteLabelTypes.mutations.setShowModalReservationConfirm
            );
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
            window.location.href = '/confirmation';
          }
          this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
        }).catch(() => {
          this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
          this.$buefy.dialog.alert({
            message: "<i class='fa fa-exclamation-circle alerticon'></i>Ocurrio un error al procesar la petición con la pasarela de pago <br><strong>Intenta realizar el pago de nuevo</strong> ",
            confirmText: 'Aceptar',
            onConfirm: () => {
              window.location.reload();
            }
          });
        });
        if (this.reservation.isEditWhiteLabel != null) {
          this.reservation.isEditWhiteLabel = null;
        }
      }
    },
    getIdentification () {
      const options = [
        {
          'id': 'CC',
          'name': 'C.C.',
          'en': 'ID',
          'type': 'number',
          'min_length': 5,
          'max_length': 20
        },
        {
          'id': 'CE',
          'name': 'C.E.',
          'type': 'number',
          'min_length': 5,
          'max_length': 20
        },
        {
          'id': 'NIT',
          'name': 'NIT',
          'type': 'number',
          'min_length': 5,
          'max_length': 20
        },
        {
          'id': 'Otro',
          'name': 'Otro',
          'type': 'number',
          'min_length': 5,
          'max_length': 20
        }
      ];
      this.identification = options.map((i) => {
        return {
          name: (this.$i18n.locale === 'en' && i.en) ? i.en : i.name,
          value: i.id
        };
      });
    },
    changedValueIdentity (values) {
      this.card.payer.identification.type = values.selectorValue;
      this.card.payer.identification.number = values.inputValue;
      if (this.enableValidations) {
        if (values.inputValue !== undefined && values.inputValue !== null && values.inputValue !== '') {
          if (!/^\d+$/.test(this.card.payer.identification.number)) {
            this.validateDoc = this.$t('checkoutValidations.documentNumber.number');
          } else {
            this.validateDoc = null;
            this.card.payer.identification.type = values.selectorValue;
            this.card.payer.identification.number = values.inputValue;
          }
        } else {
          this.validateDoc = this.$t('checkoutValidations.documentNumber.required');
        }
      }
    },
    validateIdentityType () {
      if (this.card.payer.identification.type === ' ') {
        this.card.payer.identification.type = 'CC';
      }
    }
  },
  watch: {
    reservation () {
      if (this.card.payment_method_id != null) {
        window.location.reload();
      } else {
        this.card.transaction_amount = this.reservation.balance;
        this.card.net_amount = this.reservation.balance;
      }
    },
    authTerms () {
      this.$emit('validateCheck', this.authTerms);
    },
    '$i18n.locale' (newLocale, oldLocale) {
      this.getIdentification();
    }
  }
};
</script>
<style lang="scss">
  @import "@/assets/styles/payment/_paymentCardMP.scss";
</style>
