<template>
  <div id="newCheckout"  v-if="reservation && vendor && design" :class="{noImgCheck: embedSystem}">
    <!-- Message ES/EN-->
    <b-message :active="flag" id="messDelete">
      <div class="cont-info">
        <i class="icon-alert"></i>
        <span>
          <div class="center-flex">
            <span class="text size-14 bold-400 center" style="margin-right: 10px">{{ errMessage }}</span>
          </div>
        </span>
      </div>
    </b-message>
    <!-- Modal Notification time stablished -->
    <b-modal :active.sync="showModalRedirect" :can-cancel="false" style="z-index:1000;">
      <div class="modals-bosy">
        <h3 class="title-modal">{{$t('notification')}}</h3>
        <p class="general-subtitle">{{$t('againReservation')}}.</p>
        <div class="btns-modal" style="margin-top: 30px;">
          <button
            @click="outTime"
            class="full-btn-modal btn-modal-fill"
            style="width:100%;"
          >{{$t('agree')}}</button>
        </div>
      </div>
    </b-modal>
    <!-- Loader -->
    <b-loading :active.sync="loader" :can-cancel="false" :is-full-page="isFullPage"></b-loading>
    <CustomStyles v-if="!reservation.isFree">.application--wrap { background-color: #fff!important }</CustomStyles>
    <CustomStyles v-if="reservation.isFree">
      .application--wrap { background-color:
      {{ design.bgColorCheckout }} !important }
    </CustomStyles>
    <!-- Header section -->
    <div id="top-bar-container">
      <NavBar :isButton="true" back="select" page="checkout"></NavBar>
      <stepper :paso="2" v-if="!showModalRedirect"></stepper>
    </div>
    <!-- Checkout section -->
    <div
      class="checkout-main"
      :style="{ backgroundColor: design.bgColorCheckout }"
      :class="{ noBackColor: embedSystem}"
      v-if="!showModalRedirect"
    >
      <div v-if="design && vendor">
        <!-- cambiar timeOut = false"  para no redirigir al select después de pasado el tiempo -->
        <dataReservation
          :designProps="design"
          @birthDay="setBirthDay"
          @changeCheckedPlate="changeCheckedPlate($event)"
          @showPhone="disabledPayOption($event)"
          @timeOut="showModalRedirect = $event"
          @exeperienceBlocked="isBlockedExperience = $event"
          @changeStatusValidatePhoneNumbers="changeStatusValidatePhoneNumbers"
          @changeStatusValidateDocumentNumber="changeStatusValidateDocumentNumber"
          class=""
          @validateCheck="validateCheck"
          @showTerms="showTermsCondiciones = true"
        ></dataReservation>
        <section class="check-container payment-container" v-if="reservation.isFree == false && (params.paymentTypeActive === 1 || params.paymentTypePSEActive === 1)">
          <div class="bar-pay-message" :style="[{ background: getDarkColor }]">
            <i class="icon-mks icon_paycard_checkout"></i>
            <span class="bar-pay-title">{{$t('pay')}}</span>
          </div>
          <section class="payment-options">
            <!--PAYU-->
            <selectMethodPayment :pse="showPse"></selectMethodPayment>
            <paymentCard
              :btnCheckedMainPlate="btnCheckedMainPlate"
              :showMessageCountry="showCountryMessage"
              @cancelTransactionCard="showDeclined = true, reasonDeclined = $event"
              @edit-phone="editPhone = true"
              @pendingTransactionCard="pendingTransaction"
              @validateCheck="validateCheck"
              v-if="validatePaymentMethodCreditCard('PAYU')"
            ></paymentCard>
            <paymentPse
              :btnCheckedMainPlate="btnCheckedMainPlate"
              :showMessageCountry="showCountryMessage"
              @edit-phone="editPhone = true"
              @validateCheck="validateCheck"
              v-if="validatePaymentMethodPSE('PAYU')"
            ></paymentPse>
            <!--MERCADO PAGO-->
            <paymentCardMp
              :btnCheckedMainPlate="btnCheckedMainPlate"
              :showMessageCountry="showCountryMessage"
              @cancelTransactionCard="showDeclined = true, reasonDeclined = $event"
              @edit-phone="editPhone = true"
              @pendingTransactionCard="pendingTransaction"
              @validateCheck="validateCheck"
              v-if="validatePaymentMethodCreditCard('MP')"
            ></paymentCardMp>
            <paymentPseMp
              :btnCheckedMainPlate="btnCheckedMainPlate"
              :showMessageCountry="showCountryMessage"
              @edit-phone="editPhone = true"
              @validateCheck="validateCheck"
              v-if="validatePaymentMethodPSE('MP')"
            ></paymentPseMp>
            <CreditCardWompi
              :btnCheckedMainPlate="btnCheckedMainPlate"
              :showMessageCountry="showCountryMessage"
              @cancelTransactionCard="showDeclined = true, reasonDeclined = $event"
              @edit-phone="editPhone = true"
              @pendingTransactionCard="pendingTransaction"
              @validateCheck="validateCheck"
              v-if="validatePaymentMethodCreditCard('WOMPI')"
            ></CreditCardWompi>
            <PseWompi
              :btnCheckedMainPlate="btnCheckedMainPlate"
              :showMessageCountry="showCountryMessage"
              @edit-phone="editPhone = true"
              @validateCheck="validateCheck"
              v-if="validatePaymentMethodPSE('WOMPI')"
            ></PseWompi>
          </section>
          <div class="payment-help-method-payment" v-if="!paymentType">Elige el medio de pago</div>
        </section>
        <notifications group="checkout" position="right center" />
        <div class="buton-container" v-if="design && paymentType === false && !showModalRedirect">
          <div class="check-container">
            <div
              :class="{ pay: !reservation.isFree, free: reservation.isFree }"
              class="terms"
              v-if="reservation.isFree"
            >
              <div class="copy-terms">
                <b-checkbox class="terms-label" v-model="authTerms"></b-checkbox>
                &nbsp;{{$t('termsReservation1')}}
                <a
                  @click="showTermsCondiciones = true"
                  class="link-terms"
                >{{$t('termsAndCondiW')}}</a>
                y
                <a
                  @click="showTerms = true"
                  class="link-terms"
                >{{$t('privacyPolicyW')}}*</a>
                . {{$t('termsReservation3')}}
              </div>
            </div>
            <button
              :class="{ disabled: !reservation.isFree}"
              :disabled="errors.items.length > 0 || !authTerms || validatePhoneNumbers || (params.enableIdentityDocument && validateDocumentNumber) || ((this.params.activeExperiencies === 1 && this.params.optionRequiredExperiencie === 1 && this.isBlockedExperience) && (!this.reservation.experiences || this.reservation.experiences.length == 0 || this.reservation.experiences === undefined || this.reservation.experiences == null) && !this.reservation.isEvent)"
              @click="proccessPayment()"
              class="btn-siguiente confirmarBtn"
              v-if="reservation.isFree && pageRendered"
            >{{ (isShowRequestReservation) ? $t('requestReservationConfirm') : $t('reservationConfirm') }}</button>
          </div>
        </div>
        <transactionDeclined
          :reason="reasonDeclined"
          :reservation="reservation"
          v-model="showDeclined"
        ></transactionDeclined>
        <transactionPending :reservation="reservation" v-model="showPending"></transactionPending>
        <transactionPendingCard :reservation="reservation" v-model="showPendingCard"></transactionPendingCard>
      </div>
    </div>
    <!-- Footer section -->
    <footerC
      :isFreeFooter="reservation.isFree"
      :colors="design"
      v-if="vendor && design && params && !showModalRedirect"
    ></footerC>
    <b-modal :active.sync="editPhone" :can-cancel="false">
      <div class="modals-bosy">
        <h3 class="title-modal">{{$t('modifyReservaton')}}</h3>
        <p
          class="general-subtitle"
          style="text-align: center; margin-bottom: 20px"
        >{{$t('registerPhoneReservation')}}</p>
        <form @submit.prevent="validateBeforePhone">
          <b-field
            :message="errors.first('country')"
            :type="{ 'is-danger': errors.has('country') }"
            label="Pais:"
          >
            <b-select
              @input="changedPaisSelectedReservation(option)"
              class="img-phone-country"
              icon="globe"
              icon-pack="fa"
              name="country"
              v-model="option"
              v-validate="'required'"
            >
              <option :key="key" :value="pais" v-for="(pais, key) in paises">{{ pais.label }}</option>
            </b-select>
          </b-field>
          <b-field
            :label="$t('mobile')"
            :message="errors.first('phone')"
            :type="{ 'is-danger': errors.has('phone') }"
          >
            <b-input
              autocomplete="phone-new"
              autofocus
              class="form-control form-control-sm w-100"
              icon="mobile"
              icon-pack="fa"
              name="phone"
              placeholder="Celular"
              type="text"
              v-model="phone"
              v-validate="validateTel"
            ></b-input>
          </b-field>
          <div class="btns-modal" style="margin-top: 20px">
            <button
              class="full-btn-modal btn-modal-fill"
              style="width: 100%"
              type="submit"
            >{{$t('agree')}}</button>
          </div>
        </form>
      </div>
    </b-modal>
    <div id="modal-terms">
      <b-modal :active.sync="showTerms">
        <div class="terms-body">
          <h3 class="title-modal">{{ $t('checkoutView.text1') }}</h3>
          <terms :fecha="'22 de Noviembre del 2019'"></terms>
        </div>
      </b-modal>
    </div>
    <div id="modal-terms">
      <b-modal :active.sync="showTermsCondiciones">
        <div class="terms-body">
          <h3 class="title-modal">{{ $t('checkoutView.text2') }}</h3>
          <terms2 :fecha="'22 de Noviembre del 2019'"></terms2>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
// Libraries Components
import { mapGetters, mapActions } from 'vuex';

// Components App
import NavBar from '@/components/Select/navBar';
import Stepper from '@/components/Select/stepper';
// Types - Modules
import authTypes from '@/store/types/auth';
import globalTypes from '@/store/types/global';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import dataReservation from '@/components/Checkout/dataReservation';
import selectMethodPayment from '@/components/Checkout/selectMethodPayment';
import paymentCard from '@/components/Checkout/paymentCardPayU';
import paymentCardMp from '@/components/Checkout/paymentCardMercadoPago';
import CreditCardWompi from '@/components/Checkout/CreditCardWompi';
import paymentPse from '@/components/Checkout/paymentPsePayU';
import paymentPseMp from '@/components/Checkout/paymentPseMercadoPago';
import PseWompi from '../components/Checkout/PseWompi.vue';
import footerC from '@/components/Checkout/footer';
import transactionDeclined from '@/components/Checkout/transactionDeclined';
import transactionPending from '@/components/Checkout/transactionPending';
import transactionPendingCard from '@/components/Checkout/transactionPendingCard';
import CustomStyles from '@/components/customStyles';
import terms from '@/components/Terms';
import terms2 from '@/components/Terms2';
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
import _ from 'lodash';
import { report as discordNotify } from '@/helpers/apis/discord';

export default {
  data () {
    return {
      showFooter: true,
      editPhone: false,
      phone: null,
      showDeclined: false,
      showPending: false,
      isImageModalActive: true,
      isFullPage: true,
      indicativo: null,
      pais: null,
      bandera: null,
      paises: [],
      showPendingCard: false,
      option: null,
      authTerms: true,
      showTerms: false,
      showTermsCondiciones: false,
      showCountryMessage: false,
      birthDate: null,
      showPse: true,
      flag: false,
      errMessage: '',
      validateTel: '',
      checkedMainPlate: false,
      btnCheckedMainPlate: true,
      reasonDeclined: null,
      showModalRedirect: false,
      isBlockedExperience: false,
      validatePhoneNumbers: false,
      validateDocumentNumber: false,
      pageRendered: false
    };
  },
  created () {
    this.loadScript(this.integrations);
  },
  mounted () {
    this.$store.commit(
      reservationTypes.mutations.setSelectedPeople, this.reservation.people
    );
    this.$store.commit(reservationTypes.mutations.setSelectedDate, this.reservation.dateTime);
    this.$store.commit(reservationTypes.mutations.setSelectedHour, this.reservation.dateTime);
    const reservationLocal = window.localStorage.getItem('_reservation_time');
    this.loadScriptPersonalize(this.vendor.id);
    if (reservationLocal === undefined || reservationLocal == null) {
      this.showModalRedirect = true;
      this.outTime();
    } else {
      this.$store.dispatch({
        type: 'global:getServerTime',
        data: {
          timezone: this.vendor.timezone
        }
      });
      this.validateCheckedPlate();
      this.$store.commit(authTypes.mutations.setUser);
      this.reservation.bandera = this.user.bandera;
      this.$store.dispatch({
        type: 'whiteLabel:banks',
        data: { vendorId: this.$store.getters['whiteLabel:idVendor'] }
      });
      window.localStorage.setItem(
        '_reservation',
        btoa(JSON.stringify(this.reservation))
      );
      this.$store.commit(reservationTypes.mutations.setReservationsUser);
      this.$store.commit(
        whiteLabelTypes.mutations.setShowModalReservationConfirm
      );
      let trans = this.$route.query;
      if (Object.keys(trans).length > 0) {
        if (trans.source_utm) {
          this.showPending = true;
        }
        this.showStatusTransaction(trans);
      }
      this.listPaisesReservation();
      this.validateCheck();
      this.getStatusPSE();
      this.validateTelIndicative(this.reservation.indicativo);
    }
    this.checkRedirectedWompiPayment();
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);

    setTimeout(() => {
      const topBarContainer = document.getElementById('top-bar-container');
      if (topBarContainer) {
        const height = topBarContainer.offsetHeight + 10;
        this.$store.commit(whiteLabelTypes.mutations.setTopBarSize, height);
      }
    }, 600);
    this.$nextTick(() => {
      setTimeout(() => {
        this.pageRendered = true;
      }, 1400);
    });
    if (this.reservation.isFree === false) {
      if (this.params.paymentTypeActive === 1 && this.params.paymentTypePSEActive === 0) {
        this.$store.commit(whiteLabelTypes.mutations.setPaymentType, 1);
      }
      if (this.params.paymentTypeActive === 0 && this.params.paymentTypePSEActive === 1) {
        this.$store.commit(whiteLabelTypes.mutations.setPaymentType, 2);
      }
    }
  },
  components: {
    NavBar,
    Stepper,
    dataReservation,
    selectMethodPayment,
    footerC,
    paymentCard,
    paymentPse,
    transactionDeclined,
    CustomStyles,
    transactionPending,
    transactionPendingCard,
    terms,
    terms2,
    paymentCardMp,
    paymentPseMp,
    PseWompi,
    CreditCardWompi
  },
  computed: {
    ...mapGetters({
      paymentType: whiteLabelTypes.getters.paymentType,
      reservation: reservationTypes.getters.reservationsUser,
      processing: globalTypes.getters.processing,
      stateRequiredExperience: whiteLabelTypes.getters.getStateRequiredException,
      tip: reservationTypes.getters.getTip,
      integrations: [whiteLabelTypes.getters.integrations],
      integrationDefaults: [whiteLabelTypes.getters.integrationDefaults]
    }),
    getDarkColor () {
      if (this.design.bgBarTitle !== null && this.design.bgBarTitle !== '') {
        return this.colorWithTransparency(this.design.bgBarTitle, 0.3);
      } else {
        return this.colorWithTransparency(this.design.bgColorStepper, 0.3);
      }
    }
  },
  methods: {
    ...mapActions({
      confirmReservation: reservationTypes.actions.confirmReservation,
      logout: authTypes.actions.logout
    }),
    outTime () {
      this.$store.commit(reservationTypes.mutations.setSelectedDate, null);
      this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
      this.$store.commit(reservationTypes.mutations.setSelectedTypeReservation, 'Normal');
      this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
      this.$store.commit(reservationTypes.mutations.setSelectedZone, false);
      this.$store.commit(reservationTypes.mutations.setSelectedTable, null);
      this.$store.commit(reservationTypes.mutations.setDecorationCosto, 0);
      this.$store.commit(reservationTypes.mutations.setTip, 0);
      this.$store.commit(reservationTypes.mutations.setExperienceCost, 0);
      window.localStorage.removeItem('_reservation');
      window.localStorage.removeItem('_reservation_time');
      window.localStorage.removeItem('_reservation_more_time');
      window.localStorage.removeItem('zoneId');
      window.localStorage.removeItem('_oldPayExperience');
      window.localStorage.removeItem('_oldPayExperienceFull');
      if (parseInt(this.idUser) === 13744) {
        this.logout({ id: 13744 }).then(() => {
          if (this.embedSystem) {
            window.location.href = '/select?embed=true';
          } else {
            window.location.href = '/select';
          }
          window.localStorage.removeItem('_user');
        });
      } else {
        if (this.embedSystem) {
          window.location.href = '/select?embed=true';
        } else {
          window.location.href = '/select';
        }
      }
    },
    changeCheckedPlate (event) {
      this.checkedMainPlate = event;
      this.validateCheckedPlate();
    },
    validatePaymentMethod (type) {
      const { paymentType } = this.params;
      if (paymentType === '' || paymentType === null) {
        if (
          type === 'PAYU' &&
          !this.params.isActiveMercadoPago &&
          this.authTerms &&
          this.vendor &&
          this.design &&
          this.reservation.isFree === false &&
          this.paymentType === false
        ) {
          return true;
        }
        if (
          type === 'MP' &&
          this.params.isActiveMercadoPago &&
          this.authTerms &&
          this.vendor &&
          this.design &&
          this.reservation.isFree === false &&
          this.paymentType === false
        ) {
          return true;
        }
      }
      if (
        type === paymentType &&
        this.paymentType === false &&
        this.reservation.isFree === false
      ) {
        return true;
      }
      return false;
    },
    validatePaymentMethodPSE (type) {
      const { paymentTypePSE } = this.params;
      if (paymentTypePSE === '' || paymentTypePSE === null) {
        if (
          type === 'PAYU' &&
          !this.params.isActiveMercadoPago &&
          this.vendor &&
          this.design &&
          this.reservation.isFree === false &&
          this.paymentType === 2
        ) {
          return true;
        }
        if (
          type === 'MP' &&
          this.params.isActiveMercadoPago &&
          this.vendor &&
          this.design &&
          this.reservation.isFree === false &&
          this.paymentType === 2 &&
          this.showPse
        ) {
          return true;
        }
      }
      if (type === paymentTypePSE && this.paymentType === 2) {
        return true;
      }
      return false;
    },
    validatePaymentMethodCreditCard (type) {
      const { paymentType } = this.params;
      if (paymentType === '' || paymentType === null) {
        if (
          type === 'PAYU' &&
          !this.params.isActiveMercadoPago &&
          this.vendor &&
          this.design &&
          this.reservation.isFree === false &&
          this.paymentType === 1
        ) {
          return true;
        }
        if (
          type === 'MP' &&
          this.params.isActiveMercadoPago &&
          this.vendor &&
          this.design &&
          this.reservation.isFree === false &&
          this.paymentType === 1
        ) {
          return true;
        }
      }
      if (type === paymentType && this.paymentType === 1) {
        return true;
      }
      return false;
    },
    setBirthDay (bdate) {
      this.birthDate = bdate;
    },
    disabledPayOption (value) {
      this.showCountryMessage = value;
    },
    normalizarCadena (cadena) {
      return cadena
        .replace(/\u2026/g, '...') // Reemplaza "…" por "..."
        .replace(/[\u2018\u2019]/g, "'") // Reemplaza comillas simples curvas por comillas simples
        .replace(/[\u201C\u201D]/g, '"') // Reemplaza comillas dobles curvas por comillas dobles
        .replace(/[\u2013\u2014]/g, '-') // Reemplaza guiones especiales por un guion normal
        .replace(/\u00A0/g, ' ') // Reemplaza espacio no rompedor por espacio normal
        .trim(); // Elimina espacios en blanco al inicio y al final
    },
    proccessPayment () {
      const applyStrong = window.localStorage.getItem('applyStrong');
      if (applyStrong) {
        if (!this.btnCheckedMainPlate && applyStrong === true) {
          this.$buefy.snackbar.open({
            duration: 5000,
            message:
              "<i class='fa fa-exclamation-circle alerticon'></i>Es necesario que selecciones el plato fuerte de cada persona para poder completar la reserva",
            type: 'is-warning',
            position: 'is-bottom',
            actionText: 'X',
            queue: false
          });
          return;
        }
      }
      if (this.params.isBirthDateRequired && !this.birthDate) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message:
            "<i class='fa fa-exclamation-circle alerticon'></i>Es necesario que selecciones la fecha de cumpleaños para poder completar la reserva",
          type: 'is-warning',
          position: 'is-bottom',
          actionText: 'X',
          queue: false
        });
        return;
      }
      if (this.birthDate && this.$moment().valueOf() < this.$moment(this.birthDate).valueOf()) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message:
            "<i class='fa fa-exclamation-circle alerticon'></i>La fecha de cumpleaños no puede ser mayor a la fecha actual",
          type: 'is-warning',
          position: 'is-bottom',
          actionText: 'X',
          queue: false
        });
        return;
      }
      if ((this.params.activeExperiencies === 1 && this.params.optionRequiredExperiencie === 1 && this.isBlockedExperience) && (!this.reservation.experiences || this.reservation.experiences.length === 0 || this.reservation.experiences === undefined || this.reservation.experiences === null) && !this.reservation.isEvent) {
        this.$buefy.snackbar.open({
          duration: 6000,
          message: `<div class="custom_alert"><div class="custom_alert_icon"><i class="icon_alert_danger"></i></div><div><p>Es necesario que selecciones la experiencia de cada persona para poder completar la reserva</p></div></div>`,
          type: 'is-danger is-custom',
          position: 'is-top',
          actionText: ' ',
          queue: true
        });
        return;
      }
      if (
        this.reservation.phone === '' ||
        this.reservation.phone === null ||
        !this.reservation.phone
      ) {
        this.editPhone = true;
      } else if (
        this.reservation.indicativo === '' ||
        this.reservation.indicativo === null ||
        !this.reservation.indicativo
      ) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message:
            "<i class='fa fa-exclamation-circle alerticon'></i>Debe seleccionar un país como indicativo",
          type: 'is-warning',
          position: 'is-bottom',
          actionText: 'X',
          queue: false
        });
      } else {
        // eslint-disable-next-line
        if (this.params.activeSelectorClient == '1') {
          if (
            !this.reservation.clientType ||
            this.reservation.clientType === null ||
            this.reservation.clientType === ''
          ) {
            this.flag = true;
            this.errMessage = 'Debe seleccionar un tipo de cliente';
            setTimeout(() => {
              this.flag = false;
              this.errMessage = '';
            }, 1500);
            return;
          }
          if (
            this.reservation.isActiveHuesped === 1 &&
            (this.reservation.guest === '' ||
              this.reservation.guest == null ||
              !this.reservation.guest)
          ) {
            this.flag = true;
            this.errMessage =
              'Debe especificar el ' + this.reservation.extraDescription;
            setTimeout(() => {
              this.flag = false;
              this.errMessage = '';
            }, 1500);
            return;
          }
          if (this.reservation.isActiveHuesped === 0) {
            this.reservation.guest = '';
          }
          this.flag = false;
          this.errMessage = '';
        }
        // eslint-disable-next-line
        if (this.params.activeHowMeetUs == '1') {
          if (
            !this.reservation.mediaType ||
            this.reservation.mediaType === null ||
            this.reservation.mediaType === ''
          ) {
            this.flag = true;
            this.errMessage = `Debe seleccionar una opción de ${this.params.descriptionHowMeetUs}`;
            setTimeout(() => {
              this.flag = false;
              this.errMessage = '';
            }, 1500);
            return;
          }
        }
        // eslint-disable-next-line
        if (this.params.legalPersonSelector == '1') {
          if (
            // eslint-disable-next-line
            (this.reservation.isLegalPerson &&
              // eslint-disable-next-line
              this.reservation.legalPersonName === null ||
              this.reservation.legalPersonName === '')
          ) {
            this.flag = true;
            this.errMessage = `Debe digitar el nombre de la persona jurídica`;
            setTimeout(() => {
              this.flag = false;
              this.errMessage = '';
            }, 1500);
            return;
          }
        }

        let dataBrowser = this.userAgentBrowser().parsedResult;
        this.reservation.dataBrowser = dataBrowser;
        this.reservation.isRequest = this.isShowRequestReservation;
        this.reservation.mp = this.params.isActiveMercadoPago;
        if (this.birthDate != null && this.params.activeBirthDate) {
          this.reservation.birthday = this.birthDate;
        }
        this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
        this.$store.commit(
          reservationTypes.mutations.setReservationsExecuted,
          true
        );
        this.reservation.vendorIdNum = this.decryptMx(
          this.reservation.vendorId
        );
        this.reservation.balance = (this.reservation.balance + this.tip);
        this.reservation.totalBalance = (this.reservation.totalBalance + this.tip);
        this.reservation.tip = this.tip;
        // asegurarse de que el userId siempre se seteee
        this.reservation.userId = this.user.id ? this.user.id : parseInt(localStorage.getItem('_userId'));
        if (this.reservation.comments) {
          this.reservation.comments = this.normalizarCadena(this.reservation.comments);
        }
        if (this.reservation.alergies) {
          this.reservation.alergies = this.normalizarCadena(this.reservation.alergies);
        }
        this.confirmReservation({
          reservation: this.reservation
        })
          .then((response) => {
            if (response.data.code === 799) {
              this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
              this.$buefy.snackbar.open({
                duration: 5000,
                message:
                  "<i class='fa fa-exclamation-circle alerticon'></i>" + response.data.msg,
                type: 'is-warning',
                position: 'is-bottom',
                actionText: 'X',
                queue: false
              });
              return;
            }
            if (response.data.code === 710) {
              this.$buefy.snackbar.open({
                duration: 5000,
                message:
                  "<i class='fa fa-exclamation-circle alerticon'></i>Ocurrio un error, realiza de nuevo la petición",
                type: 'is-warning',
                position: 'is-bottom',
                actionText: 'X',
                queue: false
              });
            } else {
              this.reservation.infoId = response.data.id;
              window.localStorage.setItem(
                '_reservation',
                btoa(JSON.stringify(this.reservation))
              );
              this.$store.commit(reservationTypes.mutations.setReservationsUser);
              window.localStorage.setItem('_reservationOk', true);
              this.user.birthday = this.birthDate;
              window.localStorage.setItem(
                '_user',
                btoa(JSON.stringify(this.user))
              );
              this.$store.commit(authTypes.mutations.setUser);
              this.$store.commit(
                whiteLabelTypes.mutations.setShowModalReservationConfirm
              );
              this.$store.commit(
                reservationTypes.mutations.setReservationsExecuted,
                false
              );
              this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
              if (this.embedSystem) {
                window.location.href = '/confirmation?embed=true';
              } else {
                window.location.href = '/confirmation';
              }
            }
            this.$store.commit(
              reservationTypes.mutations.setReservationsExecuted,
              false
            );
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
          })
          .catch(error => {
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
            let message = error;
            if (error.response) {
              message = error.response.status + ' - ' + error.response.data + ' ';
            }
            if (error.message) {
              message += error.message;
            }
            this.$buefy.snackbar.open({
              duration: 5000,
              message: "<i class='fa fa-exclamation-circle alerticon'></i>Ocurrio un error en la petición: " + message,
              type: 'is-warning',
              position: 'is-bottom',
              actionText: 'X',
              queue: false
            });
            if (error.response) {
              discordNotify({ title: 'Error Front Crear Reserva: Response Data', description: error.response.data, color: '16723509' });
              discordNotify({ title: 'Error Front Crear Reserva: Response Status', description: error.response.status, color: '16723509' });
            }
            if (error.request) {
              discordNotify({ title: 'Error Front Crear Reserva: Request', description: error.request, color: '16723509' });
            }
            discordNotify({ title: 'Error Front Crear Reserva: Message', description: error.message, color: '16723509' });
            discordNotify({ title: 'Error Front Crear Reserva: Config', description: error.config, color: '16723509' });
          });
      }
    },
    listPaisesReservation () {
      this.paises = this.listCountry();
    },
    changedPaisSelectedReservation (indicativo) {
      if (indicativo !== undefined) {
        let aux2 = null;
        let aux3 = indicativo.label;
        this.pais = indicativo.label;
        this.indicativo = indicativo.value;
        let ind = indicativo.value;
        _.forEach(BanderaPais, function (item) {
          let data2 = item.country + ' ( +' + ind + ' )';
          if (data2 === aux3) {
            aux2 = item.abbreviation.toLowerCase();
          }
        });
        this.bandera =
          aux2 != null
            ? 'https://api.precompro.com/banderas/' + aux2 + '.png'
            : null;
        indicativo.selected = true;
        this.option = indicativo;
        this.validateTelIndicative(indicativo.value);
      }
    },
    openPolitice () {
      this.$store.commit(whiteLabelTypes.mutations.setShowModalPolitica, true);
    },
    validateBeforePhone () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.reservation.phone = this.phone;
          this.reservation.indicativo = this.indicativo;
          this.reservation.country = this.pais;
          this.reservation.bandera = this.bandera;
          window.localStorage.setItem(
            '_reservation',
            btoa(JSON.stringify(this.reservation))
          );
          this.$store.commit(reservationTypes.mutations.setReservationsUser);
          this.user.phone = this.phone;
          window.localStorage.setItem('_user', btoa(JSON.stringify(this.user)));
          this.$store.commit(authTypes.mutations.setUser);
          this.editPhone = false;
        }
      });
    },
    showStatusTransaction (transaction) {
      if (
        parseInt(transaction.polTransactionState) === 4 &&
        parseInt(transaction.polResponseCode) === 1
      ) {
        this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
        this.$store.commit(reservationTypes.mutations.setSelectedZone, null);
        this.$store.commit(reservationTypes.mutations.setSelectedTable, null);
        window.localStorage.setItem('_reservationOk', true);
        this.$store.commit(
          whiteLabelTypes.mutations.setShowModalReservationConfirm
        );
        if (this.embedSystem) {
          window.location.href = '/confirmation?embed=true';
        } else {
          window.location.href = '/confirmation';
        }
      }
      if (
        parseInt(transaction.polTransactionState) === 6 &&
        (parseInt(transaction.polResponseCode) === 4 ||
          parseInt(transaction.polResponseCode) === 5 ||
          parseInt(transaction.polResponseCode) === 19)
      ) {
        this.showDeclined = true;
      }
      if (
        (parseInt(transaction.polTransactionState) === 12 ||
          parseInt(transaction.polTransactionState) === 14) &&
        (parseInt(transaction.polResponseCode) === 9994 ||
          parseInt(transaction.polResponseCode) === 25)
      ) {
        this.showPending = true;
      }
    },
    pendingTransaction () {
      this.showPendingCard = true;
    },
    getStatusPSE () {
      this.$http.get('payment/banks/' + this.vendor.id).then(({ data }) => {
        if (data.code === 200) {
          let pse = _.find(data.data, { id: 'pse' });
          if (pse) {
            this.showPse = true;
          } else {
            this.showPse = false;
          }
        } else {
          this.showPse = false;
        }
      });
    },
    validateCheck (authTerms = null) {
      var x = document.getElementsByClassName('terms');
      var i;
      for (i = 0; i < x.length; i++) {
        var y = x[i].getElementsByClassName('b-checkbox checkbox');
        var j;
        for (j = 0; j < y.length; j++) {
          var c = y[j].getElementsByTagName('input');
          for (var k = 0; k < c.length; k++) {
            if (c[k].type === 'checkbox') {
              if (c[k].checked) {
                var ch = y[j].getElementsByClassName('check');
                for (var l = 0; l < ch.length; l++) {
                  var urllow =
                    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath fill='%23" +
                    this.design.colorCheckboxTerms +
                    "' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E";
                  if (authTerms != null) {
                    if (authTerms) {
                      ch[l].style.background = 'url("' + urllow + '")';
                    } else {
                      ch[l].style.background = 'none';
                    }
                  } else {
                    if (this.authTerms) {
                      ch[l].style.background = 'url("' + urllow + '")';
                    } else {
                      ch[l].style.background = 'none';
                    }
                  }
                }
              } else {
                ch = y[j].getElementsByClassName('check');
                for (l = 0; l < ch.length; l++) {
                  ch[l].style.background = 'none';
                }
              }
            }
          }
        }
      }
    },
    validateCheckedPlate () {
      if (this.params.activeMainPlate === 1 && this.params.optionRequiredPlate === 1 && !this.checkedMainPlate) {
        this.btnCheckedMainPlate = false;
      } else if (this.params.activeMainPlate === 1 && this.checkedMainPlate) {
        this.btnCheckedMainPlate = true;
      } else if (this.params.activeMainPlate === 0) {
        this.btnCheckedMainPlate = true;
      }
    },
    validateTelIndicative (indicative) {
      const paisId = (indicative === null) ? 57 : parseInt(indicative);
      switch (paisId) {
        case 57:
          this.validateTel = 'required|min:10|max:10|numeric';
          break;
        case 507:
          this.validateTel = 'required|min:7|max:8|numeric';
          break;
        default:
          this.validateTel = 'required|min:7|max:11|numeric';
          break;
      }
    },
    checkRedirectedWompiPayment () {
      const transactionId = this.$route.query.id;
      if (transactionId && transactionId.includes('-')) {
        let transactionFollowUp = window.localStorage.getItem('transactionWompiFollowUpReservation');
        if (!transactionFollowUp) return;
        transactionFollowUp = JSON.parse(transactionFollowUp);
        if (transactionFollowUp.expiresAt < new Date().getTime()) return;
        this.$store.commit(whiteLabelTypes.mutations.setModalExperience, false);
        this.$store.commit(globalTypes.mutations.setVerifyingPayment, true);
        this.longPollingPseWompi(transactionFollowUp.transactionId);
      }
    },
    longPollingPseWompi (transactionId) {
      this.$http.get('/payment/status-transaction?paymentProvider=wompi&transactionId=' + transactionId)
        .then(({ data }) => {
          window.localStorage.removeItem('transactionWompiFollowUpReservation');
          if (data.status === 'APPROVED') {
            this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
            this.$store.commit(reservationTypes.mutations.setSelectedZone, false);
            this.$store.commit(reservationTypes.mutations.setSelectedTable, null);
            this.$store.commit(globalTypes.mutations.setVerifyingPayment, false);
            this.reservation.infoId = data.id;
            window.localStorage.setItem('_reservation', btoa(JSON.stringify(this.reservation)));
            this.$store.commit(reservationTypes.mutations.setReservationsUser);
            window.localStorage.setItem('_reservationOk', true);
            this.$store.commit(
              whiteLabelTypes.mutations.setShowModalReservationConfirm
            );
            if (this.embedSystem) {
              window.location.href = '/confirmation?embed=true';
            } else {
              window.location.href = '/confirmation';
            }
          }
          if (data.status === 'DECLINED' || data.status === 'ERROR') {
            this.showDeclined = true;
            this.reasonDeclined = data.message;
          }
        }).catch(() => {
          setTimeout(() => {
            this.longPollingTransaction(transactionId);
          }, 700);
        });
    },
    changeStatusValidatePhoneNumbers (status) {
      this.validatePhoneNumbers = status;
    },
    changeStatusValidateDocumentNumber (status) {
      this.validateDocumentNumber = status;
    }
  },
  watch: {
    authTerms () {
      this.validateCheck();
    },
    checkedMainPlate () {
      this.validateCheckedPlate();
    }
  }
};
</script>
<style lang="scss">
  #newCheckout{
    .hsy-dropdown > .selected{
      box-shadow: none!important;
    }
    min-height: 100vh;
    background-image: var(--imgSelect);
    background-repeat: repeat;
    background-position: top center;
    background-size: cover;
    .btn-siguiente.confirmarBtn{
      text-transform: uppercase;
      height: 44px;
      font-size: 15px;
      border-radius: 5px;
    }
    .btn-siguiente:not(:disabled){
      cursor: pointer;
    }
    .btn-pay{
      border: 0!important;
    }
    .checkout-main{
      a {
        color: var(--colorCheckout);
        font-weight: 700;
      }
      min-height: calc(100vh - 277px);
      > div:first-child{
        overflow: hidden;
        position: relative;
      }
      .buton-container{
        max-width: 939px;
        margin: 0 auto 30px;
        .check-container{
          font-size: 12px;
          .b-checkbox.checkbox input[type=checkbox]:checked + .check{
            /* background-color: var(--colorCheckout) !important; */
            border-color: var(--colorBorderCheck) !important;
          }
          .b-checkbox.checkbox input[type=checkbox] + .check{
              border-color: var(--colorBorderCheck) !important;
              background-color: var(--colorCheck1) !important;
          }
          .b-checkbox.checkbox:not(.button){
            margin-right: 0;
          }
          a{
            color: var(--colorCheckout);
            font-weight: 700;
          }
          .terms.free{
            margin-bottom: 30px;
            padding: 0px 19px;
          }
        }
      }
      .payment-container{
        max-width: 939px;
        margin: 0 auto;
        .payment-help-method-payment{
          opacity: 0.6;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          background: var(--btnBgReservationFooter);
          color: var(--btnColorReservationFooter) !important;
          border-radius: 6px;
          height: 30px;
          text-align: center;
          align-content: center;
          margin: 0 10px;
        }
        .flaggy{
          left: 8px!important;
        }
        .bar-pay-message{
          font-size: 14px;
          font-weight: 600;
          border-radius: 6px;
          padding: 7px 20px;
          display: flex;
          gap: 10px;
          align-items: flex-end;
        }
        .icon-mks{
          background: var(--colorCheckout);
        }
        .payment-options{
          padding: 16px 20px 30px!important;
        }
        .paymentCard {
          background: var(--bgColorCheckout);
          color: var(--colorCheckout);
          border: 1px solid;
          margin-bottom: 31px;
          ::placeholder{
            color: var(--colorCheckout);
          }
          .select.is-empty select{
            color: var(--colorCheckout);
          }
          option{
            color: var(--colorCheckout);
          }
          select, input{
            min-height: auto;
            color: var(--colorCheckout);
            background: var(--bgColorCheckout);
          }
          .medium-form.pse-form{
            width: 100%;
            max-width: 100%;
          }
        }
        .confirmation-section{
          font-size: 12px;
          .terms.pay{
            padding: 0px 19px;
            .copy-terms{
              margin-bottom: 30px;
              .b-checkbox.checkbox:not(.button){
                margin-right: 9px;
                .control-label{
                  padding-left: 0;
                }
              }

            }
          }
        }
      }
    }
  }
  #modal-terms {
    .animation-content.modal-content {
      max-width: 980px !important;
    }
    .terms-body {
      background: #fff;
      padding: 30px 40px 40px;
      color: #000;
      border-radius: 10px;

      .title-modal {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .modal-close{
      display: block !important;
      position: absolute;

      &::after, &::before{
        background-color: #000;
      }
    }
    @media screen and (max-width:700px) {
      .terms-body {
        padding: 40px 0px;
        #terms-html2{
          padding: 0px 20px;
        }
      }
      .modal-close{
        top: 5px;
        right: 5px;
      }
    }
  }
  @media screen and (max-width: 1280px) and (min-width: 1280px) {
  }
  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 768px) {
    #newCheckout{
      .checkout-main{
        .buton-container{
          max-width: 684px;
        }
        .payment-container{
          max-width: 684px;
          .payment-options{
            padding: 16px 35px 30px!important;
          }
        }
      }
    }
  }
  @media screen and (max-width: 576px){
    .btn-siguiente.confirmarBtn{
      position: fixed;
      bottom: 10px;
      left: 20px;
      width: calc(100% - 40px) !important;
      margin: 0;
      z-index: 1;
      height: 44px;
      font-size: 15px;
      border-radius: 5px;
    }
  }
  @media screen and (min-width:415px) and (max-width: 500px) {
    .buton-container{
      max-width: 96%!important;
    }
    .payment-container{
      max-width: 96%!important;
    }
  }
  @media screen and (max-width: 500px) {
    #newCheckout{
      .checkout-main{
        .buton-container{
          max-width: 394px;
        }
        .payment-container{
          max-width: 394px;
          .payment-options{
            padding-left: 6px!important;
            padding-right: 6px!important;
          }
        }
      }
    }
  }
  .noImgCheck {
    background-image: unset!important;
    input, textarea {
      background-color: transparent!important;
    }
    .select-input-personalizado-icon-selector {
      background-color: transparent!important;
    }
  }
  .noBackColor {
    background-color: transparent!important;
  }
</style>
