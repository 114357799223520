<template>
  <div id="experienceDetails">
    <div class="experience_details">
      <div @click="$emit('close')" class="experience_details__close">
        <img alt src="@/assets/images/icons/close.svg" srcset />
      </div>
      <h2
        class="experience_details__title pb-3"
        v-if="experience.imageExperience"
      >{{ experience.name }}</h2>
      <div class="experience_details__img_container pb-3" v-if="experience.imageExperience">
        <img :src="experience.imageExperience" alt />
      </div>
      <div class="experience_details__without_img_container" v-if="!experience.imageExperience">
        <h3 class="experience_details__title">{{ experience.name }}</h3>
      </div>
      <p class="experience_details__description pb-3" v-html="experience.description"></p>
      <div class="experience_details__price pb-3" v-if="experience.price > 0">
        <span>$ {{ experience.price | currency("", 0, { thousandsSeparator: "." }) }}</span>
        {{ $t('checkoutView.components.text1') }}
      </div>
      <div
        class="experience_details__more_info pb-3"
        v-if="experience.taxes == 1"
      >{{ $t('checkoutView.components.text2') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
  },
  props: ['experience'],
  data () {
    return {
      withoutImage: false
    };
  }
};
</script>

<style lang="scss">
#experienceDetails {
  .pb-3 {
    padding-bottom: 3rem;
  }
  line-height: 1.1;
  font-family: "Source Sans Pro" !important;
  padding: 18px;
  font-weight: 400;
  .experience_details {
    position: relative;
    padding: 22px;
    background-color: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    &__close {
      position: absolute;
      right: 23px;
      cursor: pointer;
    }
    h2.experience_details__title {
      font-family: "Source Sans Pro" !important;
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      margin-bottom: 5px;
    }
    &__img_container {
      width: 100%;
      margin-block: 5px;
    }
    &__img_container {
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__without_img_container {
      width: 100%;
      h3 {
        margin: 20px 0;
        text-align: center;
        font-weight: 600;
        font-size: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }
    }
    &__description {
      font-size: 12px;
    }
    &__price {
      font-size: 12px;
      span {
        font-weight: 600;
        font-size: 16px;
      }
    }
    &__more_info {
      font-size: 12px;
    }
  }
}
</style>
