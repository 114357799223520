<template>
    <div
        v-if="reservationDescription.typeReservation === 'Rumba' || reservationDescription.typeReservation === 'Cena' ||  reservationDescription.typeReservation === 'Brunch'"
    >
        <div v-if="reservationDescription.typeReservation === 'Cena'">
            <div class="flex-container">
                <span class="item-left-total">
                    <span class="checkout-item-subtitle">{{ $t('prePurchase') }}</span>
                    <p class="item-small-total">
                        {{ (reservationDescription.payDetails.adult) | currency('$ ', 0, { thousandsSeparator: ',' }) }}
                        * x{{ reservationDescription.people }} pax
                    </p>
                </span>
                <p class="item-right-total">
                    {{ (reservationDescription.payDetails.adult * reservationDescription.people) | currency('$ ', 0, { thousandsSeparator: ',' }) }}
                </p>
            </div>
            <div class="flex-container"  v-if="reservationDescription.payDetails.cover > 0">
                <span class="item-left-total">
                    <span class="checkout-item-subtitle">{{ $t('cover') }}</span>
                    <p class="item-small-total">
                        {{ (reservationDescription.payDetails.cover) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{$t('person')}}
                    </p>
                </span>
                <p class="item-right-total">
                    {{ (reservationDescription.payDetails.cover * reservationDescription.people) | currency('$ ', 0, { thousandsSeparator: ',' }) }}
                </p>
            </div>
        </div>
        <div v-if="reservationDescription.typeReservation === 'Brunch'">
            <div class="flex-container" v-if="reservationDescription.payDetails.cover > 0">
                <span class="item-left-total">
                    <span class="checkout-item-subtitle">{{ $t('cover') }}</span>
                    <p class="item-small-total">
                        {{ (reservationDescription.payDetails.cover) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{$t('person')}}
                    </p>
                </span>
                <p class="item-right-total">
                    {{ (reservationDescription.payDetails.cover * reservationDescription.people) | currency('$ ', 0, { thousandsSeparator: ',' }) }}
                </p>
            </div>
        </div>
        <div v-if="reservationDescription.typeReservation === 'Rumba'">
            <div class="flex-container" v-if="reservationDescription.payDetails.adult > 0 && reservationDescription.payDetails.cover == 0">
                <p class="item-final-check item-small-total">
                    {{ (reservationDescription.payDetails.adult) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{$t('person')}}
                </p>
                <p class="item-right-total">
                    {{ (reservationDescription.payDetails.adult * reservationDescription.people) | currency('$ ', 0, { thousandsSeparator: ',' }) }}
                </p>
            </div>
            <div class="flex-container" v-if="reservationDescription.payDetails.cover > 0">
                <span class="item-left-total">
                    <span class="checkout-item-subtitle">{{ $t('cover') }}</span>
                    <p class="item-small-total">
                        {{ (reservationDescription.payDetails.cover) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{$t('person')}}
                    </p>
                </span>
                <p class="item-right-total">
                    {{ (reservationDescription.payDetails.cover * reservationDescription.people) | currency('$ ', 0, { thousandsSeparator: ',' }) }}
                </p>
            </div>
            <div class="flex-container" v-if="reservationDescription.payDetails.cost > 0">
                <p class="item-small-total">
                    {{ (reservationDescription.payDetails.cost) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{$t('personConsumable')}}
                </p>
                <p class="item-right-total">
                    {{ (reservationDescription.payDetails.cost * reservationDescription.people) | currency('$ ', 0, { thousandsSeparator: ',' }) }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: ['reservationDescription']
};
</script>
